import React from 'react';

const KnowledgeGraphExplained = () => {
    return (
        <div className='privacy-policy'>
            <h1>Knowledge Graph Explained</h1>
            <h2>Graph Generation Based on Your Notes:</h2>
            <p>
                Your knowledge graph is constructed from the tags linked to your notes, representing your unique areas of interest and independent thoughts. <br/>
                Resources you add complement your notes but are not directly visualized in the graph. <br/>
                When you hover over a node, you can see the subjects that are linked by your notes.
            </p>
            <h2>Illustrating Knowledge Breadth and Depth:</h2>
            <p>
                On the homepage, the graph categorizes your learning across major knowledge domains, showcasing the breadth of your studies. <br/>
                Clicking on a domain reveals a subject page where you can view the depth of your knowledge in that area, including the status of your learning across its sub-fields and all notes associated with both the main subject and its sub-fields.
            </p>
            <h2>Dynamic Growth of Subject Nodes:</h2>
            <p>
                Nodes start as small grey dots, indicating subjects you have not yet explored or taken notes on. <br/>
                As you continue to learn and add notes, these nodes grow larger and brighter, visually representing the expanding volume of knowledge you have accumulated within each subject.
            </p>
            <h2>Reminders for Knowledge Revisitation:</h2>
            <p>
            The graph uses italics for the names of subjects that you have studied but not revisited lately, serving as a visual cue to review and reinforce your learning in these areas.
            </p>
        </div>
    );
};

export default KnowledgeGraphExplained;