import React, {useState, useEffect, useContext} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';
import { TbDownload } from "react-icons/tb";
import { createRoot } from 'react-dom/client';
import { FaLink } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { getSingleNote } from '@linko/shared_utils';

const NoteForExport = () => {
    const location = useLocation();
    const params = useParams();
    const encodedNoteId = params.encodedNoteId;
    const noteId = atob(encodedNoteId);
    const {isAuthenticated} = useContext(AuthContext);
    const { userInfo } = useContext(UserContext);
    const [note, setNote] = useState(null);
    const noteTitle = note ? note.title : null;
    const noteContent = note ? note.note : null;
    const noteSubject = note ? note.subject : null;
    const resourceTitle = note?.resource?.[0]?.title;
    const resourceAuthor = note?.resource?.[0]?.author;
    const type = note?.resource?.[0]?.type;
    const formattedTime = note?.update_time ? new Date(note.update_time).toLocaleDateString('en-US') : null;
    const [editorState, setEditorState] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const myNote = note?.user?.id === userInfo.id;

    useEffect(() => {
        getSingleNote(noteId)
        .then((response) => {
            setNote(response.data);
        })
        .catch((error) => {
            console.error('Error fetching note:', error.response ? error.response.data : error.message);
        });
    }, [noteId]);

    useEffect(() => {
        if (noteContent) {
            const rawContentFromDB = JSON.parse(noteContent);
            const contentState = convertFromRaw(rawContentFromDB); 
            setEditorState(EditorState.createWithContent(contentState));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [noteContent]);

    const exportNoteAsImage = () => {
        const tempDiv = document.createElement('div');
        createRoot(tempDiv).render(
            <div id="export-note-card" />
        );
        document.body.appendChild(tempDiv); 
        setTimeout(() => {
            const element = document.getElementById('export-note-card'); // Select the element with the id 'export-note-card'
            if (element) {
                html2canvas(element).then(canvas => {
                    const link = document.createElement('a');
                    link.download = `LinkoNote${encodedNoteId}.png`;
                    link.href = canvas.toDataURL();
                    link.click();
                });
            } else {
                console.error('Element not found');
            }
            document.body.removeChild(tempDiv); // Remove the div from the body after capturing the image
        }, 1000); // Wait for 1 second to ensure the component has finished rendering
    }

    const copyNoteLink = () => {
        navigator.clipboard.writeText(`www.linkolll.com/note/${encodedNoteId}`);
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 2000);
    };

    return (
        <div className='note-for-export'>
            {myNote && 
                <div className='export-note-actions'>
                    <TbDownload className='export-note-action' onClick={exportNoteAsImage} title='Download as image'/>
                    <FaLink className='export-note-action' onClick={copyNoteLink} title='Copy link to note'/>
                    {linkCopied && <p className='link-copied'>Link copied!</p>}
                </div>
            }
            <div id='export-note-card'>
                {note && note.user && <h1 className='username'>{note.user.first_name} {note.user.last_name}</h1>}                
                <p className='time'>Written on {formattedTime}</p>
                <div className='card-for-share'>
                    <h1 className='note-title'>{noteTitle}</h1>
                    <div className='note-content'>
                        {editorState && <Editor editorState={editorState} readOnly={true} />}
                    </div>
                    {noteSubject &&
                        <div className='subject'>
                            {noteSubject.filter(item => item.is_linked).map((item, index) => {
                                return (
                                    <p key={index} className='subject-item'>
                                        {item.name}
                                    </p>
                                );
                            })}
                        </div>
                    }
                    {resourceTitle &&
                    <>
                    <hr />
                    <div className='resource'>
                        <ResourceTypeIcon type={type} />
                        <h1 className='resource-title'>{resourceTitle}</h1>
                    </div>
                    {resourceAuthor && 
                    <>
                        <h1 className='resource-author'> By {resourceAuthor}</h1>
                    </>
                    }
                    </>
                    }
                </div>
                <div className='logo-footer'>
                    <div className='brand'>
                        <p className='logo'>
                            Linko
                        </p>
                        <p className="slogan">
                            Learning as a lifestyle
                        </p>
                    </div>
                    <p className='link'>
                        www.linkonote.com
                    </p>
                </div>
            </div>

        </div>
    );
}
export default NoteForExport;