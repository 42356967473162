import React, {useContext} from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useLocation } from "react-router-dom";

function HalfFooter() {
    const { safeNavigate } = useContext(NavigationPromptContext);

    return (
        <div id="half-footer">
            <a href="https://linko.featurebase.app/" className='footer-link'>Feedback</a>
            <a href="https://linko.featurebase.app/changelog" className='footer-link'>Changelog</a>
            <div className='footer-link' onClick={() => safeNavigate('/term_of_service')}>Terms</div>
            <div className='footer-link' onClick={() => safeNavigate('/privacy_policy')}>Privacy</div>
        </div>
    );
}

export default HalfFooter;
