import React, { useState, useEffect, useContext } from 'react';
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import ResourceTypeIcon from '../../Components/ResourceCard/ResourceTypeIcon';
import { searchAuthorBooks, postNewResource, fetchSingleResource } from '@linko/shared_utils';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { useToast } from '../../Components/ToastNotification';

const BookOverview = ({ title, description, author }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [otherBooks, setOtherBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const { safeNavigate } = useContext(NavigationPromptContext);
  const { setPersistentMessage } = useToast();
  const truncatedDescription = description?.substring(0, 300) + '...';

  useEffect(() => {
    searchAuthorBooks(title, author).then(results => setOtherBooks(results));
  }, []);


  const handleSubmit = async () => {
    if (!selectedBook) {
      return;
    }
    const response = await postNewResource(selectedBook);
    const newResourceId = response.data.id;
    const newResourceResponse = await fetchSingleResource(newResourceId);
    if (newResourceResponse.status === 200) {
      safeNavigate(`/r/${newResourceId}`);
    }
  };

  useEffect(() => {
    if (selectedBook) {
      handleSubmit();
    }
  }, [selectedBook]);

  return (
    <div className="resource-book-overview">
      <div className="book-description" style={{marginBottom: '20px'}}>
        <p><strong>Description</strong></p>
        <div className='rx-divider-vertical' style={{margin:'5px 0'}}/>
        {showFullDescription ? (
          <div dangerouslySetInnerHTML={{ __html: description }} style={{color: '#666666'}}/>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} style={{color: '#666666'}}/>
        )}
        {description?.length > 300 && 
          <button onClick={() => setShowFullDescription(!showFullDescription)} className='note-expand-button' style={{padding:'5px 0'}}>
              {showFullDescription ? 'Show less' : 'Show more'}
              {showFullDescription ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
          </button>
        }
      </div>
      {otherBooks && otherBooks.length > 0 && (
        <div className="other-books">
        <p><strong>Other Books by {author}</strong></p>
        <div className='rx-divider-vertical' style={{margin:'5px 0'}}/>
            {otherBooks.slice(0, 5).map((book, index) => (
              <div className='search-results-resource' key={index} onClick={() => setSelectedBook(book.id)}>
                <ResourceTypeIcon type={'bo'} />
                {book.volumeInfo.title}
                {/* <div className='add-to-library'><MdOutlineLibraryAdd style={{marginTop:'3px'}}/>Add to My Library</div> */}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default BookOverview;