import React, { useEffect, useRef } from 'react';

const BookPreview = ({ embeddable, loaded, bookId }) => {

    const viewerRef = useRef(null);

    useEffect(() => {
        if (loaded) {
            if (viewerRef.current !== null) {
                const viewer = new window.google.books.DefaultViewer(viewerRef.current);
                viewer.load(bookId);
            }
        }
    }, [loaded, bookId]);

    return (
        <div style={{position:'relative', overflow:'hidden', width:'100%'}}>
            {embeddable ? (
                loaded ? (
                    <div ref={viewerRef} id='googleBookViewer'/>
                ) : (
                    <div style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'12px'}}>
                        Loading preview...
                    </div>
                )
            ) : (
                <div style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'12px'}}>
                    Preview not available
                </div>
            )}
        </div>
    );
};

export default BookPreview;
