import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const fetchArticleReader = async (url) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        
        if (!accessToken) {
            throw new Error('No access token found');
        }

        if (!url) {
            throw new Error('URL is required');
        }

        const response = await axios.get('/api/utils/fetch_article_content/', {
            params: { url },
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching article content:', error);
        throw error;
    }
}