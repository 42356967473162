import React, {useState, useContext} from 'react';
import { FollowContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useModal } from '../../Context/ModalContext';
import { handleFollowAction } from '@linko/shared_utils';

const FollowList = ({ followList, setFollowList, followType}) => {
    const [removed, setRemoved] = useState({});
    const title = followType === 'Follower' ? 'Your Followers' : 'You are following';
    const removeType = followType === 'Follower' ? 'Remove' : 'Unfollow';
    const removedNotif = followType === 'Follower' ? 'Follower removed' : 'Unfollowed';
    const navigate = useNavigate();
    const { fetchFollowData } = useContext(FollowContext);
    const { requestConfirm } = useModal();

    const friendProfile = (index) => {
        const initial = followList[index].first_name.charAt(0).toLowerCase() + followList[index].last_name.charAt(0).toLowerCase();
        const encodedId = followList[index].id * 912;
        navigate(`/profile/${initial}${encodedId}`);
    }
    
    const handleRemove = async (id, name, event) => {
        event.stopPropagation();
        const removeMessage = followType === 'Follower' ?  `remove ${name} from your follower list` : `unfollow ${name}`;
        const removeType = followType === 'Follower' ? 'Remove' : 'Unfollow';
        const action = followType === 'Follower' ? 'remove follower' : 'unfollow';
        requestConfirm(
            `Are you sure you want to ${removeMessage}?`,

            async () => {
                const response = await handleFollowAction(id, action);
                if (response.status === 200) {
                    setRemoved(prevState => ({ ...prevState, [id]: true }));
                    setFollowList(followList.filter((item) => item.id !== id));
                    fetchFollowData();
                } else {
                    window.alert('There was an error removing the follower, please try again later.');
                }
            },
            null,
            removeType
        );
    }
    
    return (
        <div className='follow-list'>
            <h2 className='follow-title'>{title}</h2>
            {followList.length > 0 && followList.map((followItem, index) => (
                <div className='follow-list-item' key={index} >
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div className='follow-avatar'>
                            <Avatar name={`${followItem.first_name} ${followItem.last_name}`} size={25} round={true} color="#48c78e" />
                        </div>
                        <div className='follower-info'>
                            <p onClick={() => friendProfile(index)}>{followItem.first_name} {followItem.last_name}</p>
                        </div>
                    </div>
                    {removed[followItem.id] ? 
                    <p className='action-buttons' style={{fontSize:'14px'}}>{removedNotif}</p>
                    :
                    <div className='action-buttons'>
                        <button className='delete-follow' onClick={(event) => handleRemove(followItem.id, followItem.first_name, event)}>{removeType}</button>
                    </div>
                    }
                </div>
            ))
            }
        </div>
    );
};

export default FollowList;