import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [historyStack, setHistoryStack] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const pushPage = (page) => {
        setHistoryStack((prevStack) => [...prevStack, page]);
    };

    const goBack = () => {
        setHistoryStack((prevStack) => {
            const newStack = [...prevStack];
            newStack.pop();
            return newStack;
        });
        if (historyStack.length > 1) {
            navigate(-1);
        } else {
            navigate('/my_linko');
        }
    };

    return (
        <NavigationContext.Provider value={{ pushPage, goBack }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);