import React, { useState, useEffect, useRef, useContext } from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { IoClose } from "react-icons/io5";
import Loader from "../Loader";
import BookThumbnailPlaceholder from "./BookThumbnailPlaceholder";
import { useToast } from '../ToastNotification';
import BookManualInput from "./BookManualInput";
import { searchBooks, postResourceAndLearn, fetchSingleResource } from '@linko/shared_utils';

const AddBook = ({ setSelectedResource, onClose }) => {
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openManualInput, setOpenManualInput] = useState(false);
    const { setPersistentMessage } = useToast(); 
    const { safeNavigate } = useContext(NavigationPromptContext);
    const timeoutId = useRef();
    const inputRef = useRef();
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [modalRef, onClose]);

    useEffect(() => {
        inputRef.current && inputRef.current.focus(); 
    }, []);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
    };

    const handleSubmit = async () => {
        setSubmissionLoading(true);
        if (!selectedBook) {
            return;
        }
        try {
            const response = await postResourceAndLearn(selectedBook);
            const newResourceId = response.data.id;
            setSubmissionLoading(false);
            onClose();
            const newResourceResponse = await fetchSingleResource(newResourceId);
            if (newResourceResponse.status === 200) {
                if (setSelectedResource) {
                    setSelectedResource(newResourceResponse.resource.resource);
                } else {
                    safeNavigate(`/r/${newResourceId}`);
                }
                setPersistentMessage('Book added successfully!');
            };
        } catch (error) {
            console.error("Error submitting book:", error);
            setSubmissionLoading(false);
            setPersistentMessage('Failed to add book. Please try again.');
        }
    };

    useEffect(() => {
        if (selectedBook) {
            handleSubmit();
        }
    }, [selectedBook]);

    useEffect(() => {
        if (searchTerm === "") {
            setIsLoading(false);
        }
        if (searchTerm !== "") {
            setIsLoading(true);
            timeoutId.current && clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(async () => {
                try {
                    const results = await searchBooks(searchTerm);
                    setSearchResults(results);
                    setIsLoading(false);
                } catch (error) {
                    console.error(
                        "Error fetching books:",
                        error.response ? error.response.data : error.message
                    );
                    setIsLoading(false);
                }
            }, 2000);
        } else {
            setSearchResults([]);
        }

        return () => clearTimeout(timeoutId.current);
    }, [searchTerm]);

    if (openManualInput) {
        return (
            <BookManualInput 
                onClose={onClose}
                setOpenManualInput={setOpenManualInput}
            />
        )
    }

    return (
        <div className="add-modal" ref={modalRef}>
        {submissionLoading ? <Loader /> : (
            <>
            <div className="add-modal-header">
                <h2 className="add-type-title">Add Book</h2>
                <button className="close-button">
                    <IoClose onClick={onClose} />
                </button>
            </div>
            <form className="add-book-form">
                <label style={{ width: "100%" }}>
                    <input
                        className="search-books"
                        type="text"
                        placeholder="Search book"
                        ref={inputRef}
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        onKeyDown={handleEnterKey} 
                        required
                    />
                </label>
            </form>
            <div className="book-search-results">
                {isLoading ? (
                    <div className="search-loader">
                        <Loader />
                    </div>
                ) : (
                    <>
                    {searchResults.map((book) =>
                        book.id ? (
                            <div
                                key={book.id}
                                className="book-search-result"
                                onClick={() => {
                                    setSelectedBook(book.id);
                                }}
                            >
                                {book.volumeInfo &&
                                book.volumeInfo.imageLinks &&
                                book.volumeInfo.imageLinks.thumbnail ? (
                                    <img
                                        className="search-book-img"
                                        src={
                                            book.volumeInfo.imageLinks.thumbnail
                                        }
                                        alt={book.volumeInfo.title}
                                    />
                                ) : (
                                    <BookThumbnailPlaceholder />
                                )}
                                <div className="search-book-info">
                                    <p className="search-book-title">
                                        {book.volumeInfo.title}
                                        {book.volumeInfo &&
                                            book.volumeInfo.subtitle &&
                                            `: ${book.volumeInfo.subtitle}`}
                                    </p>
                                    <p className="search-book-author">
                                        {book.volumeInfo.authors &&
                                            `By ${book.volumeInfo.authors}`}
                                        {book.volumeInfo.authors &&
                                            book.volumeInfo.publishedDate &&
                                            `, ${new Date(
                                                book.volumeInfo.publishedDate
                                            ).getFullYear()}`}
                                    </p>
                                </div>
                            </div>
                        ) : null
                    )}
                    </>
                )}
            </div>
            </>
        )}
        </div>
    );
};

export default AddBook;
