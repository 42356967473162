import React, {useContext} from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useLocation } from "react-router-dom";

function Footer() {
    const location = useLocation();
    const { safeNavigate } = useContext(NavigationPromptContext);
    const paths = ['/my_linko', '/s/', '/r/', '/profile/', '/my_library'];
    const hideFooter = paths.some(path => location.pathname.startsWith(path));    
    
    if (hideFooter) {
        return null;
    }
    return (
        <div id="footer" className={location.pathname === '/' ? 'landing-page-footer' : null}>
            <a href="https://linko.featurebase.app/" className='footer-link'>Feedback</a>
            <a href="https://linko.featurebase.app/changelog" className='footer-link'>Changelog</a>
            <div className='footer-link' onClick={() => safeNavigate('/term_of_service')}>Terms</div>
            <div className='footer-link' onClick={() => safeNavigate('/privacy_policy')}>Privacy</div>
        </div>
    );
}

export default Footer;
