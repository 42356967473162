import { IoLibraryOutline } from "react-icons/io5";
import { GoPeople } from "react-icons/go";
import { PiNotePencilBold } from "react-icons/pi";
import { LiaShareAltSolid } from "react-icons/lia";

export const setupTabs = (location, setTabNames) => {
    if (location.pathname === '/my_linko') {
      setTabNames({
        first: <span className="button-span"><IoLibraryOutline/> Mine</span>,
        second: <span className="button-span"><GoPeople style={{strokeWidth:'0.75px'}}/> Following</span>,
      });
    } else if (location.pathname.startsWith('/s/')) {
      setTabNames({
        first: <span className="button-span"><IoLibraryOutline/>Mine</span>,
        second: <span className="button-span"><LiaShareAltSolid style={{strokeWidth:'0.75px', marginTop:'3px'}}/> Following</span>,
      });
    } else if (location.pathname.startsWith('/r/')) {
      setTabNames({
        first: <span className="button-span"><PiNotePencilBold /> Mine </span>,
        second: <span className="button-span"><LiaShareAltSolid size={22} style={{strokeWidth:'0.75px', marginTop:'3px'}}/> Following</span>,
      });
    } else if (location.pathname.startsWith('/n/')) {
      setTabNames({
        first: <span className="button-span"><PiNotePencilBold/> Related Notes</span>,
      });
    }
  };
