import React from 'react';
import { RiBook2Line } from "react-icons/ri";

const BookThumbnailPlaceholder = () => {
    return (
        <div className='book-thumbnail-placeholder'>
            <div className='book-thumbnail-placeholder-background'>
                <RiBook2Line className='book-thumbnail-placeholder-icon'/>
            </div>
        </div>
    );
};

export default BookThumbnailPlaceholder;
