import React, {useEffect, useState, useContext} from 'react';
import { UserSubjectContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useToast } from '../../Components/ToastNotification';
import { useModal } from '../../Context/ModalContext';
import EditCustomTag from './EditCustomTag';
// Icons
import { FiEdit3 } from "react-icons/fi";

const SubjectPageHeader = ({
    editTag,
    setEditTag,
    subjectName, 
    publicField, 
    subjectId, 
    description, 
    setDescription
}) => {

    const [newSubjectName, setNewSubjectName] = useState(null);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { setPersistentMessage }= useToast();
    const { requestConfirm } = useModal();
    const { fetchUserSubject } = useContext(UserSubjectContext);

    useEffect(() => {
        setNewSubjectName(subjectName);
    }, [subjectName]);
    
    return (
        <div className='field-path'>
        {editTag ? 
            <EditCustomTag 
                subjectName={subjectName}
                newSubjectName={newSubjectName} 
                setNewSubjectName={setNewSubjectName} 
                description={description}
                setDescription={setDescription}
                setEditTag={setEditTag} 
                subjectId={subjectId} 
                fetchUserSubject={fetchUserSubject} 
                safeNavigate={safeNavigate} 
                setPersistentMessage={setPersistentMessage} 
                requestConfirm={requestConfirm}
            />
            :
            <h1 className='subject-name' style={{ display: 'inline' }}>{newSubjectName}</h1>
        }
        {publicField === false && !editTag &&
            <FiEdit3 onClick={() => setEditTag(true)} title='Edit tag' size={22} style={{cursor:'pointer', color:'#666666', marginTop:'8px'}} />
        }
      </div>
    );
};

export default SubjectPageHeader;