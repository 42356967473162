import React, { useState, useEffect, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import NoteCard from '../NoteCard/NoteCard'; 
import { getSingleNote } from '@linko/shared_utils';
import { LuShuffle } from "react-icons/lu";
import Loader from '../Loader';


const TabRevisit = ({
  revisitSubject, 
  revisitNote, 
  setRevisitNote, 
  isShufflingSubject, 
  setIsShufflingSubject
}) => {

  const { safeNavigate } = useContext(NavigationPromptContext);

  const handleShuffle = () => {
    setIsShufflingSubject(true);
  };

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const deleteNote = (noteId) => {
    setRevisitNote(revisitNote.filter(note => note.id !== noteId));
  };

  const updateNoteContent = (updatedNote) => {
    setRevisitNote(revisitNote.map(note => note.id === updatedNote.id ? updatedNote : note));
  };

  const updateSingleNote = (noteId) => {
    getSingleNote(noteId)
    .then((response) => {
        const updatedNote = response.data.note;
        setRevisitNote(revisitNote.map(note => note.id === noteId ? updatedNote : note));
    })
    .catch((error) => {
        console.error('Error fetching note:', error.response ? error.response.data : error.message);
    });
  }

  return (
    <>
      <div className="revisit-header">
        <h2 className="subject-title" onClick={() => safeNavigate(`/s/${encodeURIComponent(capitalize(revisitSubject))}`)}>
          {isShufflingSubject ? '...' : capitalize(revisitSubject)}
        </h2>
        <button className="linko-button linko-button--tertiary shuffle-button" onClick={handleShuffle}>
          {/* <LuShuffle size={18}/> */}
           Shuffle
        </button>
      </div>
      {isShufflingSubject ? 
        <div className='tab-content-loader'><Loader size={15}/></div>
        :
        <div className="cards-container" style={{marginBottom:'30px'}}>
          {revisitNote && (
            revisitNote.map((note) => (
              <div className="revisit-note" key={note.id}>
                <NoteCard 
                  key={note.id}
                  note={note}
                  onDelete={deleteNote}
                  onUpdate={updateNoteContent}
                  shareView={false}
                  subjectId={null}
                  updateSingleNote={updateSingleNote}
                />
              </div>
            ))
          )}
        </div>
      }
    </>
  );
};

export default TabRevisit;
