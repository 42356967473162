import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Editor, EditorState, ContentState, convertToRaw, convertFromHTML, RichUtils, getDefaultKeyBinding, CompositeDecorator, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import showdown from 'showdown';
// icons 
import { LuHeading } from "react-icons/lu";
import { BsChatLeftQuote } from "react-icons/bs";
import { VscListUnordered } from "react-icons/vsc";
import { GoListOrdered } from "react-icons/go";
import { BiBold } from "react-icons/bi";
import { PiTextItalicBold } from "react-icons/pi";
import { BiLink } from "react-icons/bi";

const converter = new showdown.Converter({
  simpleLineBreaks: true,
  literalMidWordUnderscores: true,
});

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: '#3b5998', textDecoration: 'underline' }}>
      {props.children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

const customLineBreakDecorator = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const blockType = contentBlock.getType();
  const isListItem = blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

  let matchArr, start;
  const regex = isListItem ? /\n/g : /\n\n/g;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + (isListItem ? 1 : 2));
  }
};

const LineBreakSpan = (props) => {
  return <br />;
};

const StyleButton = ({ onToggle, style, label, active }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <span 
      className="RichEditor-styleButton" 
      onMouseDown={onMouseDown}
      style={{ color: active ? '#267953' : '#999999' }}
    >
      {label}
    </span>
  );
};

const RichEditor = ({ value, onChange, placeholder }) => {
  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: customLineBreakDecorator,
        component: LineBreakSpan,
      },
    ]);
    return EditorState.createEmpty(decorator);
  });
  const editorRef = useRef(null);
  const isInternalChange = useRef(false);

  const createContentState = useCallback((content) => {
    if (!content) return ContentState.createFromText('');
    
    // Convert content to HTML, preserving newlines
    const html = converter.makeHtml(content);
    
    // Custom processing to handle newlines
    const processedHtml = html.replace(
      /(<(ul|ol)>[\s\S]*?<\/(ul|ol)>)|(\n)/g,
      (match, listContent, listType, _, newline) => {
        if (listContent) {
          // For list content, replace \n with <br> inside <li> tags
          return listContent.replace(/(<li[^>]*>)([\s\S]*?)(<\/li>)/g, (m, openTag, content, closeTag) => {
            return openTag + content.replace(/\n/g, '<br>') + closeTag;
          });
        } else if (newline) {
          // For single newlines outside lists, replace with space
          return ' ';
        }
        return match;
      }
    );
    
    const blocksFromHTML = convertFromHTML(processedHtml);
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
  }, []);

  useEffect(() => {
    if (!isInternalChange.current && value) {
      const newContentState = createContentState(value);
      const newEditorState = EditorState.createWithContent(newContentState);
      setEditorState(newEditorState);
    }
    isInternalChange.current = false;
  }, [value, createContentState]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const rawContent = convertToRaw(newEditorState.getCurrentContent());
    const markdown = convertToMarkdown(rawContent);
    isInternalChange.current = true;

    onChange(markdown);
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === 'split-block-and-reset-type') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      
      // Split the block
      let newContentState = Modifier.splitBlock(contentState, selection);
      
      // Get the key of the new block
      const blockAfterSplit = newContentState.getBlockAfter(selection.getStartKey());
      
      // Change the type of the new block to unstyled
      newContentState = Modifier.setBlockType(
        newContentState,
        selection.merge({
          anchorKey: blockAfterSplit.getKey(),
          focusKey: blockAfterSplit.getKey(),
          anchorOffset: 0,
          focusOffset: 0,
        }),
        'unstyled'
      );
      
      const newEditorState = EditorState.push(editorState, newContentState, 'split-block');
      
      handleEditorChange(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
      return 'handled';
    }

    // Handle toggle commands
    if (command.startsWith('toggle-')) {
      const blockType = command.replace('toggle-', '');
      let newEditorState = RichUtils.toggleBlockType(editorState, blockType);

      // Remove the triggering characters
      const selection = newEditorState.getSelection();
      const content = newEditorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let charsToRemove = 0;
      if (text.startsWith('#')) charsToRemove = 1;
      if (text.startsWith('##')) charsToRemove = 2;
      if (text.startsWith('* ')) charsToRemove = 2;
      if (text.startsWith('1. ')) charsToRemove = 3;
      if (text.startsWith('> ')) charsToRemove = 2;

      if (charsToRemove > 0) {
        const newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );
        newEditorState = EditorState.push(newEditorState, newContent, 'remove-range');
      }

      handleEditorChange(newEditorState);
      return 'handled';
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn = (e) => {
    if (e.key === ' ') {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let newBlockType = null;
      let charsToRemove = 0;

      if (text === '#') { newBlockType = 'header-two'; charsToRemove = 1; }
      // else if (text === '##') { newBlockType = 'header-two'; charsToRemove = 2; }
      else if (text === '*') { newBlockType = 'unordered-list-item'; charsToRemove = 1; }
      else if (text === '1.') { newBlockType = 'ordered-list-item'; charsToRemove = 2; }
      else if (text === '>') { newBlockType = 'blockquote'; charsToRemove = 1; }

      if (newBlockType) {
        e.preventDefault();  // Prevent the space from being inserted
        
        // Remove the triggering characters
        let newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );

        // Change the block type
        newContent = Modifier.setBlockType(
          newContent,
          newContent.getSelectionAfter(),
          newBlockType
        );

        const newEditorState = EditorState.push(editorState, newContent, 'change-block-type');
        handleEditorChange(newEditorState);
        return 'handled';
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const blockType = block.getType();

      if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
        const newEditorState = RichUtils.onTab(e, editorState, 4);
        handleEditorChange(newEditorState);
        return 'handled';
      }
    }

    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    handleEditorChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const addLink = () => {
    const selection = editorState.getSelection();
    const link = window.prompt('Enter a URL:');
    if (!link) {
      return;
    }
    
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    let newEditorState;
    
    if (selection.isCollapsed()) {
      // If no text is selected, insert the URL as the link text
      const textWithEntity = Modifier.insertText(
        contentStateWithEntity,
        selection,
        link,
        null,
        entityKey
      );
      newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    } else {
      // If text is selected, apply the link to the selection
      const withLink = Modifier.applyEntity(
        contentStateWithEntity,
        selection,
        entityKey
      );
      newEditorState = EditorState.push(editorState, withLink, 'apply-entity');
    }
    
    setEditorState(newEditorState);
    
    // Force update after state change
    setTimeout(() => {
      handleEditorChange(newEditorState);
    }, 0);
  };

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const convertToMarkdown = (rawContent) => {
    let markdown = '';
    let listItemNumbers = [1];

    rawContent.blocks.forEach((block, index) => {
      let blockText = block.text;
      let inlineStyleRanges = [...block.inlineStyleRanges];
      let entityRanges = [...block.entityRanges];
      
      // Create an array of all style changes
      let styleChanges = [];
      inlineStyleRanges.forEach(range => {
        styleChanges.push({ offset: range.offset, type: 'start', style: range.style });
        styleChanges.push({ offset: range.offset + range.length, type: 'end', style: range.style });
      });
      
      // Sort style changes by offset
      styleChanges.sort((a, b) => a.offset - b.offset || (a.type === 'end' ? 1 : -1));
      
      let styledText = '';
      let currentStyles = new Set();
      let lastOffset = 0;
      
      styleChanges.forEach(change => {
        // Add text since last change
        styledText += blockText.slice(lastOffset, change.offset);
        
        // Update current styles
        if (change.type === 'start') {
          currentStyles.add(change.style);
        } else {
          currentStyles.delete(change.style);
        }
        
        // Add style markers
        if (change.type === 'start') {
          if (change.style === 'BOLD') styledText += '**';
          if (change.style === 'ITALIC') styledText += '_';
        } else {
          if (change.style === 'ITALIC') styledText += '_';
          if (change.style === 'BOLD') styledText += '**';
        }
        
        lastOffset = change.offset;
      });
      
      // Add remaining text
      styledText += blockText.slice(lastOffset);
      
      // Handle entities (like links)
      entityRanges.forEach(range => {
        const entity = rawContent.entityMap[range.key];
        if (entity.type === 'LINK') {
          const start = range.offset;
          const end = start + range.length;
          const linkText = styledText.slice(start, end);
          const link = `[${linkText}](${entity.data.url})`;
          styledText = styledText.slice(0, start) + link + styledText.slice(end);
        }
      });
      
      blockText = styledText;

      const depth = block.depth || 0;
      const indent = '  '.repeat(depth);

      // Handle block types
      switch (block.type) {
        case 'header-one':
          markdown += `# ${blockText}\n\n`;
          break;
        case 'header-two':
          markdown += `## ${blockText}\n\n`;
          break;
        case 'blockquote':
          markdown += `> ${blockText}\n\n`;
          break;
        case 'unordered-list-item':
          markdown += `${indent}* ${blockText}\n`;
          break;
        case 'ordered-list-item':
          if (depth >= listItemNumbers.length) {
            listItemNumbers.push(1);
          } else {
            listItemNumbers = listItemNumbers.slice(0, depth + 1);
          }
          markdown += `${indent}${listItemNumbers[depth]}. ${blockText}\n`;
          listItemNumbers[depth]++;
          break;
        default:
          markdown += `${blockText}\n\n`;
      }

      // Add an extra newline after lists if the next block is not a list item
      if ((block.type === 'unordered-list-item' || block.type === 'ordered-list-item') &&
          (!rawContent.blocks[index + 1] || 
           (rawContent.blocks[index + 1].type !== 'unordered-list-item' && 
            rawContent.blocks[index + 1].type !== 'ordered-list-item'))) {
        markdown += '\n';
      }
      if (index < rawContent.blocks.length - 1 && rawContent.blocks[index + 1].text === '') {
        markdown += '\n\n';
      }
      if (blockText.trim() === '') {
        // For empty blocks, add two newlines
        markdown += '\n\n';
      }
    });

    return markdown.trim();
  };
  
  const isStyleActive = (style) => {
    if (style.startsWith('header-') || style === 'blockquote' || style.endsWith('-list-item')) {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    }
    return editorState.getCurrentInlineStyle().has(style);
  };

  return (
    <div className="RichEditor-root">
      <div className="RichEditor-controls">
        <StyleButton onToggle={toggleBlockType} style="header-two" label={<LuHeading />} active={isStyleActive('header-two')} />
        <StyleButton onToggle={toggleBlockType} style="blockquote" label={<BsChatLeftQuote />} active={isStyleActive('blockquote')} />
        <StyleButton onToggle={toggleInlineStyle} style="BOLD" label={<BiBold />} active={isStyleActive('BOLD')} />
        <StyleButton onToggle={toggleInlineStyle} style="ITALIC" label={<PiTextItalicBold />} active={isStyleActive('ITALIC')} />
        <StyleButton onToggle={toggleBlockType} style="unordered-list-item" label={<VscListUnordered />} active={isStyleActive('unordered-list-item')} />
        <StyleButton onToggle={toggleBlockType} style="ordered-list-item" label={<GoListOrdered />} active={isStyleActive('ordered-list-item')} />
        <StyleButton onToggle={addLink} style="LINK" label={<BiLink />} active={false} />
      </div>
      <div onClick={focusEditor} className="RichEditor-content">
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={handleEditorChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
        />
      </div>
    </div>
  );
};

export default React.memo(RichEditor);