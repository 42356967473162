import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';

const FramePreview = ({ url, setOmitPreview }) => {
    const [hasError, setHasError] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 670);
    const [secureURL, setSecureURL] = useState('');

    const handleError = () => {
        setHasError(true);
        setOmitPreview(true);
    };

    useEffect(() => {
        let processedURL;
        if (url.endsWith('.pdf')) {
            processedURL = url.replace(/\.pdf$/, '');
        } else if (url.includes('arxiv.org/abs/')) {
            processedURL = url.replace('/abs/', '/pdf/');
        } else {
            processedURL = url;
        }
        setSecureURL(processedURL);
        setHasError(false);
    }, [url]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 670);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (hasError || isSmallScreen) {
        return null;
    }

    return (
        <div className='frame-preview'>
            <Iframe 
                url={secureURL}
                width="100%"
                height="100%"
                id="myId"
                display="initial"
                position="relative"
                onError={handleError}
            />
        </div>
    );
};

export default FramePreview;
