import React, {useEffect, useState, useRef, useContext} from 'react';
import ReactDOM from 'react-dom';
import { AddNoteCardContext } from '../../Context/AppContext';
import tippy, {hideAll} from 'tippy.js';
import { BsChatLeftQuote } from "react-icons/bs";
import { fetchArticleReader } from '@linko/shared_utils';

const ArticleReader = ({url, setOmitPreview}) => {

    const articleReaderRef = useRef(null);
    const tooltipRef = useRef(null);
    const [articleContent, setArticleContent] = useState('');
    const [hasError, setHasError] = useState(false);
    const [isTooltipInteracted, setIsTooltipInteracted] = useState(false);
    const { setAddNoteCard, setNewNoteContent } = useContext(AddNoteCardContext);
    const [selectedText, setSelectedText] = useState('');
    let tippyInstance = null;

    const fetchArticleContent = async () => {
        try {
            const response = await fetchArticleReader(url);
            if (response.data.content === 'No article found') {
                setHasError(true);
                setOmitPreview(true);
                return;
            }
            setArticleContent(response.data.content);
        } catch (error) {
            handleError();
        }
    }

    useEffect(() => {
        fetchArticleContent();
    }, [url]);

    useEffect(() => {
        const links = articleReaderRef.current?.getElementsByTagName('a');
        if (links) {
            for (let link of links) {
                link.addEventListener('click', handleLinkClick);
            }
        }
        return () => {
            if (links) {
                for (let link of links) {
                    link.removeEventListener('click', handleLinkClick);
                }
            }
        }
    }, [articleContent]);

    const handleMouseUp = () => {
        const text = window.getSelection().toString().trim();
        const articleContent = document.getElementById('articleContent');
        const clickInsideTooltip = tooltipRef.current && tooltipRef.current.contains(window.getSelection().anchorNode);

        if (text && articleContent && articleContent.contains(window.getSelection().anchorNode)) {
            setSelectedText(text);

            // Get the coordinates of the selected text
            const rect = window.getSelection().getRangeAt(0).getBoundingClientRect();

            hideAll();

            // Create a new tippy instance
            tippyInstance = tippy(articleContent, {
                showOnCreate: true,
                hideOnClick: false,
                trigger: 'manual',
                placement: 'bottom',
                interactive: true,
                theme: 'heavy-shadow',
                getReferenceClientRect: () => rect,
                onHide: () => {
                    setIsTooltipInteracted(false);
                }
            });

            // Create a new div element
            const div = document.createElement('div');
            div.style.display = 'flex';
            div.style.alignItems = 'center';
            div.style.columnGap = '8px';
            div.style.padding = '4px';
            div.style.cursor = 'pointer';
    
            // Create a new p element and icon
            const p = document.createElement('p');
            p.textContent = 'Quote this and create a new note';
            p.style.margin = '0px';
            
            // Create a new icon element
            const iconContainer = document.createElement('span');
            ReactDOM.render(<BsChatLeftQuote />, iconContainer);
            iconContainer.style.width = '20px';
            iconContainer.style.height = '20px';
            iconContainer.style.fontSize = '18PX';
            iconContainer.style.marginTop = '2px';

            // Append the icon and p elements to the div
            div.appendChild(iconContainer);
            div.appendChild(p);
            
            // Add an event listener for the click event
            p.addEventListener('click', () => {
                setIsTooltipInteracted(true);
                handleConfirm(text);
                tippyInstance.hide();
            });
    
            // Update the tooltip's content
            tippyInstance.setContent(div);

        } else if (!text && !isTooltipInteracted) {
            hideAll();
        }
    };

    const handleConfirm = (text) => {
        setNewNoteContent(text);
        if (tippyInstance) {
            tippyInstance.hide();
        }
        
    }

    const handleError = () => {
        setHasError(true);
        console.error('Error fetching article content');
    }

    const handleLinkClick = (event) => {
        if (!window.confirm('Would you like to go to this URL?')) {
            event.preventDefault();
        }
    }

    if (hasError) {
        return null; 
    }

    return (
        <div className='article-reader' 
            ref={(node) => {
                articleReaderRef.current = node;
            }} 
            onMouseUp={handleMouseUp}
        >
            <div dangerouslySetInnerHTML={{ __html: articleContent }} id='articleContent'/>
        </div>
    );
};

export default ArticleReader;