import React from 'react';
import { useLocation } from 'react-router-dom';

const NoContentPlaceholder = ({ activeTab }) => {

    const location = useLocation();

    if (location.pathname.startsWith('/my_linko') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5' >
                <div>
                    <h1 style={{fontSize:'18px', fontWeight:'500', lineHeight:'1.5'}}>Get started with Linko!</h1>
                    <div  style={{marginTop:'8px', lineHeight:'2'}}>
                        <li><span style={{fontWeight:'600'}}>Add resource: </span> search for books or paste a link. </li>
                        <li><span style={{fontWeight:'600'}}>Take notes: </span> independent or linked to a resource. </li>
                        <li><span style={{fontWeight:'600'}}>Explore: </span> follow your friends and see what they are learning</li>
                        <li><span style={{fontWeight:'600'}}>A personal knowledge graph </span> that reflects your learning journey.</li>
                    </div>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/my_linko') && activeTab === 'revisit') {
        return (
            <div className='linko-card mt-5' >
                Unlock smart revisit feature by adding at least 10 notes
            </div>
        );
    }

    if (location.pathname.startsWith('/my_linko') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <h1 style={{fontSize:'18px', fontWeight:'500', lineHeight:'1.5'}}>Follow other users to see what they are learning!</h1>
                {/* <span style={{fontWeight:'600'}}>Who to follow:</span><br/>
                <ul>
                    <li>People you follow</li>
                    <li>People you know</li>
                </ul> */}
            </div>
        );
    }
     
    if (location.pathname.startsWith('/s/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                No one has added any learning record for this subject yet.<br/>
                Be the first one to share your learning journey!
            </div>
        );
    }

    if (location.pathname.startsWith('/s/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                Start taking notes or add a resource under this subject!
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'notes') {
        return (
            <div className='linko-card mt-5'>
                Start taking notes under this resource! 
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                No one has added any learning record for this resource yet.<br/>
                Be the first one to share your learning journey!
            </div>
        );
    }

    if (location.pathname.startsWith('/n/') && activeTab === 'notes') {
        return (
            <div className='linko-card mt-5'>
                No related notes found. 
                Add more notes to see related notes here.
            </div>
        );
    }

    if (location.pathname.startsWith('/profile/')) {
        return (
            <div className='linko-card mt-5'>
                This user has not added any learning record yet.
            </div>
        );
    }
};

export default NoContentPlaceholder;