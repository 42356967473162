import react, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationPromptProvider } from './NavigationPromptContext';
import { fetchUserSubject, fetchMyData, fetchUserFollow } from '@linko/shared_utils';

export const SubjectContext = createContext();
export const UserSubjectContext = createContext();
export const SubjectIdContext = createContext();
export const UserContext = createContext();
export const FollowContext = createContext();
export const FocusContext = createContext();
export const AuthContext = react.createContext();
export const AddNoteCardContext = react.createContext();
export const OnboardingContext = react.createContext();
export const LoadingContext = createContext();

export function AppContextProvider({ children }) {
  const [userSubject, setUserSubject] = useState([]);
  const [userFans, setUserFans] = useState([]);
  const [subjectId, setSubjectId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [requesting, setRequesting] = useState([]);
  const [focus, setFocus] = useState(false);
  const [addNoteCard, setAddNoteCard] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Authenticated
  const checkAuthStatus = () => {
    const token = localStorage.getItem('access_token');
    return token !== null && token !== '';
  };
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

  useEffect(() => {
    const protectedRoutes = ['/login', '/register', '/forget_password', '/'];
    if (isAuthenticated && protectedRoutes.includes(location.pathname)) {
      navigate('/my_linko');
    }
  }, [location, isAuthenticated, navigate]);

  const fetchUserSubjectData = async () => {
    const data = await fetchUserSubject();
    setUserSubject(data.userSubject);
    setUserFans(data.userFans);
  };

  const fetchUserInfo = async () => {
    const data = await fetchMyData();
    setUserInfo(data);
  };

  const fetchFollowData = async () => {
    const data = await fetchUserFollow();
    setFollowings(data.following);
    setFollowers(data.follower);
    setNotifications(data.request_pending);
    setRequesting(data.requesting);
  };

  const fetchUserData = async () => {
    setIsLoading(true);
    await Promise.all([
      fetchUserInfo(),
      fetchFollowData(),
      fetchUserSubjectData()
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();

      // const userInfoInterval = setInterval(() => {
      //   fetchUserInfo();
      // }, 5 * 60 * 1000);

      // return () => clearInterval(userInfoInterval);
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  return (
      <NavigationPromptProvider>
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <AddNoteCardContext.Provider value={{ addNoteCard, setAddNoteCard, newNoteContent, setNewNoteContent }}>
            <FocusContext.Provider value={{ focus, setFocus }}>
              <FollowContext.Provider value={{ fetchFollowData, followings, setFollowings, followers, setFollowers, notifications, setNotifications, requesting, setRequesting }}>
                <UserContext.Provider value={{ userInfo, setUserInfo, fetchUserInfo }}>
                  <SubjectIdContext.Provider value={{ subjectId, setSubjectId }}>
                    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkAuthStatus}}>
                      <UserSubjectContext.Provider value={{ userSubject, setUserSubject, fetchUserSubjectData, userFans }}>
                        {children}
                      </UserSubjectContext.Provider>
                    </AuthContext.Provider>
                  </SubjectIdContext.Provider>
                </UserContext.Provider>
              </FollowContext.Provider>
            </FocusContext.Provider>
          </AddNoteCardContext.Provider>
        </LoadingContext.Provider>
      </NavigationPromptProvider>
  );
}

export default AppContextProvider;