import React, { createContext, useContext, useState } from 'react';
import DeleteConfirmModal from '../Components/Modals/DeleteConfirmModal';
import NoteLikeModal from '../Components/Modals/NoteLikeModal';
import AlertModal from '../Components/Modals/AlertModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState('Confirm');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [onCancel, setOnCancel] = useState(() => () => {});

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [onInfoOk, setOnInfoOk] = useState(() => () => {}); 

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [onAlertOk, setOnAlertOk] = useState(() => () => {});

  const requestConfirm = (message, confirmCallback, cancelCallback, action = 'Confirm') => {
    setMessage(message);
    setConfirmAction(action);
    setOnConfirm(() => () => {
      confirmCallback();
      setIsVisible(false); // Hide modal after confirm
    });
    setOnCancel(() => () => {
      setIsVisible(false); // Hide modal after cancel
    });
    setIsVisible(true);
  };

  const requestInfo = (data, okCallback) => {
    setInfoData(data);
    setOnInfoOk(() => () => {
      okCallback();
      setIsInfoVisible(false); // Hide modal after OK
    });
    setOnCancel(() => () => {
      setIsInfoVisible(false); // Hide modal after cancel
    });
    setIsInfoVisible(true);
  };

  const requestAlert = (message) => {
    setAlertMessage(message);
    setOnAlertOk(() => () => {
      setIsAlertVisible(false); // Hide modal after OK
    });
    setIsAlertVisible(true);
  }

  return (
    <ModalContext.Provider value={{ requestConfirm, requestInfo, requestAlert }}>
      {children}
      <DeleteConfirmModal isOpen={isVisible} message={message} confirmAction={confirmAction} onConfirm={onConfirm} onCancel={onCancel} />
      <NoteLikeModal isOpen={isInfoVisible} data={infoData} onOk={onInfoOk} onCancel={onCancel}/>
      <AlertModal isOpen={isAlertVisible} message={alertMessage} onOk={onAlertOk} />
    </ModalContext.Provider>
  );
};
