import React, { useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigation } from '../../Context/NavigationContext';
import BackButton from '../../Components/BackButton';
import PublicProfileHeader from './PublicProfileHeader';
import TabLibrary from '../../Components/ContentTabs/TabLibrary';
import { fetchUserData, fetchMyFeed } from '@linko/shared_utils'; 

const PublicProfile = () => {

  const [privateAccount, setPrivateAccount] = useState(false);
  const [privateMessage, setPrivateMessage] = useState({library:'', graph: ''});
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastNameInitial, setUserLastNameInitial] = useState(null);
  const [library, setLibrary] = useState([]);
  const [libraryOffset, setLibraryOffset] = useState(0);
  const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(false);
  const [hasMoreLibrary, setHasMoreLibrary] = useState(true);
  const limit = 10;
  const { userCode } = useParams();
  const location = useLocation();
  const { pushPage } = useNavigation();
  const numericPart = userCode.replace(/\D/g, '');
  const userId = parseInt(numericPart) / 912;

  const fetchUserName = async () => {
    const response = await fetchUserData(userId);
    setUserFirstName(response.data.first_name);
    setUserLastNameInitial(response.data.last_name.charAt(0));
    if (response.data.private_account) {
      setPrivateAccount(true);
      setPrivateMessage(prevMessage => ({...prevMessage, library: 'This account is private. Follow the user to view their library.'}));
    }
  };

  const fetchLibrary = async () => {
    if (!hasMoreLibrary) return;
    try {
      const response = await fetchMyFeed(limit, libraryOffset, null, userId, setLibrary, setLibraryOffset, setHasMoreLibrary, setIsFetchingMoreLibrary);
      if (response?.status === 400) {
        setPrivateAccount(true);
        setPrivateMessage(prevMessage => ({...prevMessage, library: 'This profile does not exist.' }));
      } else if (response?.status === 203) {
        setPrivateAccount(true);
        setPrivateMessage(prevMessage => ({...prevMessage, library: 'This account is private. Follow the user to view their library.'}));
      } 
    } catch (error) {
      console.error('Error fetching library:', error);
    } finally {
      setIsFetchingMoreLibrary(false);
    }
  };

  const handleScrollEvent = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setIsFetchingMoreLibrary(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    fetchUserName();
    setIsFetchingMoreLibrary(true);
  }, []);

  useEffect(() => {
    if (isFetchingMoreLibrary) {
      fetchLibrary();
    }
  }, [isFetchingMoreLibrary]);

  useEffect(() => {
    pushPage(location.pathname);
  }, [location.pathname]);

    return (
      <div className='page-container public-profile'>
        <div className='page-right'>
        <BackButton />
          <PublicProfileHeader 
            userId={userId}
            userFirstName={userFirstName}
            userLastNameInitial={userLastNameInitial}
          />
          {privateAccount ?
            <div className='note-card-wrapper' style={{marginTop:'30px'}}>
              <div className='note-card' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', rowGap:'10px', color:'#666666'}}>
                {privateMessage.library}
              </div>
            </div>
            :
            <div className='the-tabs'>
              <div className="tab-content">
                <div id='tab-library'>
                <TabLibrary
                  library={library}
                  isFetchingMoreLibrary={isFetchingMoreLibrary}
                  hasMoreLibrary={hasMoreLibrary}
                />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
};

export default PublicProfile;