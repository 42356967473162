import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoClose } from "react-icons/io5";
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext(null);

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const [persistentMessage, setPersistentMessage] = useState('');

    const CloseButton = ({ closeToast }) => {
        return (
            <IoClose onClick={closeToast} size={20} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color:'#c1c1c1', fontWeight:'600' }}/>
        );
    };
    

    const addToast = message => {
        toast(message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            progress: undefined, 
            closeButton: <CloseButton/>,
            style: {
                // borderRadius: '10px',
                borderLeft: '5px solid #48c78e',
            },
            progressStyle: {
                backgroundColor: '#48c78e !important',
            },
        });
    };

    useEffect(() => {
        if (persistentMessage) {
            addToast(persistentMessage);
            setPersistentMessage('');  // Reset persistent message
        }
    }, [persistentMessage, addToast]);

    return (
        <ToastContext.Provider value={{ addToast, setPersistentMessage }}>
            {children}
            <ToastContainer />
        </ToastContext.Provider>
    );
};