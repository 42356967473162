import React, {useEffect, useState} from 'react';
import BookPreview from './BookPreview';
import VideoPlayer from './VideoPlayer';
import ArticleReader from './ArticleReader';
import FramePreview from "./FramePreview";
import ResourceDescription from './ResourceDescription';
import BookOverview from './BookOverview';
import { IoClose } from "react-icons/io5";
import { parseUrl, loadBookPreview } from './parseUrl';

const ResourcePageRightTabs = ({ resource }) => {

    let id, title, author, type, description, month, year, url, platform, image_link;
    if (resource && resource.resource) {
        ({
            id,
            title,
            author = "",
            type, 
            description,
            month, 
            year, 
            link: url,
            platform, 
            image_link,
        } = resource.resource);
    } else {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource);
    }

    const [tabNames, setTabNames] = useState([]);
    const [tab, setTab] = useState('');
    const [omitDescription, setOmitDescription] = useState(false);
    const [omitPreview, setOmitPreview] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [embeddable, setEmbeddable] = useState(false);

    const bookUrl = new URL(url);
    const bookId = bookUrl.pathname.split('/').pop();
    const isBook = type === "bo";
    const isVideo = type === "vi";
    const isArticle = type === "ar";

    // Check if screen is small
    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1024);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleTabClick = (content) => {
        if (isSmallScreen) {
            setTab(content);
            setIsModalOpen(true);
        } else { 
            setTab(content);
        }
    };

    const { isYouTube, videoId, isPDF } = parseUrl(url);

    useEffect(() => {
        const cleanup = loadBookPreview(bookId, setLoaded, setOmitPreview, setEmbeddable);
        return cleanup;
    }, [bookId]);

    useEffect(() => {
        let newTabNames = [];
        if (isBook) {
            newTabNames.push('Overview');
            newTabNames.push('Preview');
        } else if (isVideo && isYouTube) {
            newTabNames.push('Watch video');
        } else if (isArticle && !isPDF) {
            if (!omitPreview) newTabNames.push('Read');
            if (!omitDescription) newTabNames.push('Description');
        } else if (isArticle && isPDF) {
            if (!omitPreview) newTabNames.push('Preview PDF');
            if (!omitDescription) newTabNames.push('Description');
        } else {
            if (!omitDescription) newTabNames.push('Description');
        }
        setTabNames(newTabNames);
    }, [isBook, isVideo, isYouTube, isArticle, isPDF, omitDescription, omitPreview]);

    useEffect(() => {
        if (tabNames.length > 0) {
            if (window.innerWidth >= 1024) {
                setTab(tabNames[0]);
            } else {
                setTab('');
            }
        } 
    }, [tabNames]);

    useEffect(() => {
        if (description === null || description === '') {
            setOmitDescription(true);
        }
    }, [description]);

    return (
        <div className='resource-page-right-wrapper'>
            <div className='book-tabs'>
                {tabNames.map((tabName, index) => (
                    <button 
                        key={index}
                        className={`tab-button ${tab === tabName ? 'active' : ''}`}
                        onClick={() => handleTabClick(tabName)}
                    >
                        {tabName}
                    </button>
                ))}
            </div>
            {!isSmallScreen && 
            <div style={{width:'100%'}}>
                {tab === 'Overview' && <BookOverview title={title} description={description} author={author} />}  
                {tab === 'Preview' && <BookPreview embeddable={embeddable} loaded={loaded} bookId={bookId}/>}
                {tab === 'Description' && <ResourceDescription description={description} />}
                {tab === 'Watch video' && <VideoPlayer videoId={videoId} setOmitPreview={setOmitPreview} description={description}/>}
                {tab === 'Read' && <ArticleReader url={url} setOmitPreview={setOmitPreview}/>}
                {tab === 'Preview PDF' && <FramePreview url={url} setOmitPreview={setOmitPreview}/>}
            </div>
            }
            {isSmallScreen && isModalOpen &&
                <div className='whole-page-modal' > 
                    <div style={{width:'100%', height:'60px', display:'flex', justifyContent:'flex-end', paddingRight:'10px'}}>
                        <button onClick={() => setIsModalOpen(false)} className='close-button'><IoClose /></button>
                    </div>
                    {tab === 'Overview' && <BookOverview title={title} description={description} author={author}/>}
                    {tab === 'Preview' && <BookPreview embeddable={embeddable} loaded={loaded} bookId={bookId}/>}
                    {tab === 'Description' && <ResourceDescription description={description} />}
                    {tab === 'Watch video' && <VideoPlayer videoId={videoId} setOmitPreview={setOmitPreview} description={description}/>}
                    {tab === 'Read' && <ArticleReader url={url} setOmitPreview={setOmitPreview}/>}
                    {tab === 'Preview PDF' && <FramePreview url={url} setOmitPreview={setOmitPreview}/>}
                </div>
            }
        </div>
    );
};

export default ResourcePageRightTabs;