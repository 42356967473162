import React, {useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FollowContext, UserContext } from '../../Context/AppContext';
import { useNavigation } from '../../Context/NavigationContext';
import FollowRequest from './FollowRequest';
import NameCard from './NameCard';
import Notifications from './Notifications';
import Followers from './Followers';
import Followings from './Followings';
import Settings from './Settings';

const AccountPage = () => {

    const { followings, followers, notifications } = useContext(FollowContext);
    const { userInfo } = useContext(UserContext);
    const { pushPage } = useNavigation();
    const location = useLocation();
    const initialTab = location.state?.activeTab || 'notifications';
    const [activeTab, setActiveTab] = useState(initialTab);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        pushPage(location.pathname);
    }, [location.pathname]);

    if (!userInfo) {
        return null; 
    } 

    return (
        <div className='account-page'>
            <div className='profile-menu'>
                <NameCard 
                    followers={followers} 
                    followings={followings} 
                    userInfo={userInfo}
                    handleTabChange={handleTabChange}
                />
                <div className="account-tabs">
                    {/* {followers.length > 0 &&  */}
                    <button
                        className={`notification-tab tab-button ${activeTab === 'notifications' ? 'active' : 'inactive'}`}
                        onClick={() => handleTabChange('notifications')}
                        style={{display: 'flex', alignItems: 'center', columnGap:'30px'}}
                    >
                        Notifications
                        {userInfo.new_likes > 0 && <div className="notification-count">{userInfo.new_likes}</div>}
                    </button>
                    
                    <button
                        className={`notification-tab tab-button ${activeTab === 'follow-request' ? 'active' : 'inactive'}`}
                        onClick={() => handleTabChange('follow-request')}
                        style={{display: 'flex', alignItems: 'center', columnGap:'30px'}}
                    >
                        Follow Request
                        {notifications.length > 0 && <div className="notification-count">{notifications.length}</div>}
                    </button>
                    <button
                        className={`notification-tab tab-button ${activeTab === 'settings' ? 'active' : 'inactive'}`}
                        onClick={() => handleTabChange('settings')}
                    >
                        Settings
                    </button>
                </div>
            </div>

            <div className='vertical-line'></div>
            <div className='profile-content'>

                {activeTab === 'notifications' && (
                    <Notifications />
                )}
                
                {activeTab === 'follow-request' && (
                    <FollowRequest 
                        notifications={notifications}
                    />
                )}

                {activeTab === 'settings' && (
                    <Settings userInfo={userInfo}/>
                )}
                
                {activeTab === 'followers' && (
                    <Followers followers={followers}/>
                )}

                {activeTab === 'followings' && (
                    <Followings followings={followings}/>
                )}


            </div>
        </div>
    );
};

export default AccountPage;