import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { register } from '@linko/shared_utils';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [requirements, setRequirements] = useState({
      length: false,
      lettersAndNumbers: false,
    });
  const validRegister = requirements.length && requirements.lettersAndNumbers;
  const navigate = useNavigate();
  const [registerStatus, setRegisterStatus] = useState('');
  const [isError, setIsError] = useState(false);
  
  const checkPasswordRequirements = () => {
      setRequirements({
        length: password.length >= 9,
        lettersAndNumbers: /\d/.test(password) && /[a-zA-Z]/.test(password),
      });
    };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  useEffect(() => {
      password &&
      checkPasswordRequirements();
    }, [password]);

  const handleSubmit = async (event) => {
    event.preventDefault();
      checkPasswordRequirements();
      if (!requirements.length || !requirements.lettersAndNumbers) {
      event.preventDefault();
      let alertMessage = 'Cannot submit form because:';
      if (!requirements.length) {
        alertMessage += '\n- Password must contain at least 9 characters.';
      }
      if (!requirements.lettersAndNumbers) {
        alertMessage += '\n- Password must contain both letters and numbers.';
      }
      window.alert(alertMessage);
    } else {
      try {
        await register(firstName, lastName, email, password);
        localStorage.setItem('justRegistered', 'true');
        localStorage.setItem('email', email);
        navigate('/thank_you_for_registering');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.email && error.response.data.email[0] === "user with this email already exists.")  {
          setIsError(false);
          setRegisterStatus('Account already exists.');
          localStorage.setItem('successNotice', 'Account already exists, please log in.');
          navigate('/login');
        }
        console.error('Register failed:', error);
      }
    }
  };

  return (
    <div className='login'>
      <div className='login-form-container'>
        <form onSubmit={handleSubmit} className='login-form'>
          <div className='login-form-content'>
            <h2 className='welcome-back'>Welcome to Linko!</h2>
            <div className='user-name-enter'>
              <div className="login-form-group">
                <label className='login-form-label'>First Name</label>
                <input 
                  className="login-form-control" 
                  placeholder="Enter first name" 
                  name='first_name'  
                  type='text' 
                  value={firstName}
                  required 
                  onChange={e => setFirstName(e.target.value)}/>
              </div>
              <div className="login-form-group">
                <label className='login-form-label'>Last Name</label>
                <input 
                  className="login-form-control" 
                  placeholder="Enter last name" 
                  name='last_name'  
                  type='text' 
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}/>
              </div>
            </div>
            <div className="login-form-group">
              <label className='login-form-label'>Email</label>
              <input 
                className="login-form-control" 
                placeholder="Enter email" 
                name='email'  
                type='text' 
                value={email}
                required
                autoComplete='email' 
                onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className="login-form-group">
              <label className='login-form-label'>Password</label>
              <div className='password-input'>
                <input 
                    name='password' 
                    type={showPassword ? "text" : "password"}     
                    className="login-form-control password-input"
                    placeholder="Enter password"
                    value={password}
                    autoComplete='current-password'
                    required
                    onFocus={() => setIsPasswordInputFocused(true)}
                    onChange={e => setPassword(e.target.value)}
                    style={{paddingRight: '50px'}} // Make room for the icon
                />
                <div 
                    onClick={togglePasswordVisibility}
                    className='password-visibility-icon'
                >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                </div>
              </div>
              <div className='password-requirement'>
                <p className={
                    !isPasswordInputFocused ? '' :
                    requirements.length ? 'requirement-met' : 'requirement-not-met'
                }>
                * must contain at least 9 characters. 
                </p>    
                <p className={
                    !isPasswordInputFocused ? '' :
                    requirements.lettersAndNumbers ? 'requirement-met' : 'requirement-not-met'
                }>
                * must contain both letters and numbers.
                </p>
            </div>
            </div>
            <div className="login-form-footer">
              <p className={`login-status ${isError ? 'error-notice' : 'success-notice'}`}>{registerStatus}</p>
              <div className="login-form-footer-right">
                <div className="login-form-link">
                    <p>Already have an account?  <Link to="/login">Log in</Link></p>
                </div>
                <button type="submit" className="login-button" style={{backgroundColor: validRegister ? "#48c78e" : "#C1c1c1"}}>
                  Register
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
