import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const putMySubject = async (subjectId, newSubjectName, newDescription) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const response = await axios.put(`/api/subject/${subjectId}/`, {
            new_name: newSubjectName,
            new_description: newDescription
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating subject:', error);
        throw error;
    }
};

export const deleteMySubject = async (subjectId) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const response = await axios.delete(`/api/subject/${subjectId}/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting subject:', error);
        throw error;
    }
};
