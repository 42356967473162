import React, { useState } from 'react';
import { FaRegQuestionCircle } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import { putUserData } from '@linko/shared_utils';
import { useToast } from '../../Components/ToastNotification';

const Settings = ({ userInfo }) => {
    const [privateAccount, setPrivateAccount] = useState(userInfo.private_account);
    const { addToast } = useToast();

    const handlePrivacyChange = async (newValue) => {
        try {
            await putUserData({ private_account: newValue });
            setPrivateAccount(newValue);
            addToast('Privacy settings updated successfully', { appearance: 'success' });
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            addToast('Failed to update privacy settings', { appearance: 'error' });
        }
    };

    return (
        <div className='notifications'>
            <h2 className='follow-title'>Privacy Setting</h2>
            <div className='setting-group'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='setting-item'>My profile is visible to: </p>
                    &nbsp;
                    <Tippy 
                        interactive={true}
                        content={"Your profile includes your public notes, resources, and knowledge graph."}
                        placement="right"
                        arrow={true}
                        theme='light-border'
                        delay={[0, 0]} 
                    >
                        <div>
                            <FaRegQuestionCircle color='#999999' style={{ marginTop: '8px' }}/> 
                        </div>
                    </Tippy>
                </div>
                <div className='setting-option'>
                    <button
                        className={`option ${!privateAccount ? 'selected' : ''}`}
                        onClick={() => handlePrivacyChange(false)}
                    >
                        Everyone
                    </button>
                    <button
                        className={`option ${privateAccount ? 'selected' : ''}`}
                        onClick={() => handlePrivacyChange(true)}
                    >
                        Followers
                    </button>
                </div>
            </div>    
        </div>
    );
};

export default Settings;