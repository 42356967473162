import React, {useState} from 'react';
import FollowList from './FollowList';

const Followers = ({ followers}) => {
    const [followList, setFollowList] = useState(followers);
    return (
        <FollowList followList={followers} setFollowList={setFollowList} followType='Follower' />
    );
};

export default Followers;