import React, {useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { handleLogout } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';

const NameDropdown = ({userInfo, notifications}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [notificationCount, setNotificationCount] = useState(0);
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setUserInfo } = useContext(UserContext);

    useEffect(() => {
        setNotificationCount(notifications.length + userInfo?.new_likes);
    }, [notifications, userInfo?.new_likes, location]);

    const handleLogoutPress = async () => {
        try {
            await handleLogout();
            setIsAuthenticated(false);
            setUserInfo(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location]);

    return (
        <div 
        className="username-dropdown" 
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)} 
        >
            <button className="navbar-user-name" >
                {userInfo && <span>{userInfo.first_name}</span>}
            </button>
            {notificationCount > 0 && <div className="notification-badge"></div>}
            {isDropdownOpen && (
                <div className="name-dropdown-menu">
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/account/${userInfo.first_name}${userInfo.last_name}`, {state: {activeTab: 'notifications'}})}>
                        <p>Notifications</p>
                        {notificationCount > 0 && <div className="notification-count">{notificationCount}</div>}
                    </div>
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/account/${userInfo.first_name}${userInfo.last_name}`, {state: {activeTab: 'settings'}})}>
                        <p>Settings</p>
                    </div>
                    <div className='dropdown-item setting' onClick={() => window.open('https://linko.featurebase.app/')}>
                        <p>Feedback</p>
                    </div>
                    <div className='dropdown-divider'></div>
                    <div className="logout" onClick={handleLogoutPress}>
                        <p>Logout </p>
                    </div>
                </div>
            )}
      </div>
    );
};

export default NameDropdown;