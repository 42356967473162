import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const handleLogin = async (email, password, setIsError, setLoginStatus, setAccessToken) => {
  const user = {
    email: email.toLowerCase(),
    password: password,
  };
  try {
    const response = await axios.post("/api/auth/login/", user, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      await storageUtils.clear();
      await storageUtils.setItem("access_token", response.data.access);
      await storageUtils.setItem("refresh_token", response.data.refresh);
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.access}`;
      setIsError(false);
      setLoginStatus("Login successful");
      setAccessToken(response.access);
    } else if (response.status === 403) {
      setIsError(false);
      setLoginStatus("Email not verified. A new activation email has been sent.");
    } else {
      setIsError(true);
      setLoginStatus("Invalid email or password");
    }
  } catch (error) {
    setIsError(true);
    setLoginStatus("Invalid email or password");
  }
};
  

