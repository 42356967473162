import React from 'react';
import { FiBook } from "react-icons/fi";
import { LuGraduationCap } from "react-icons/lu";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { LuHeadphones } from "react-icons/lu";
import { FaRegNewspaper } from "react-icons/fa6";
import { GrArticle } from "react-icons/gr";
import { PiGlobe} from "react-icons/pi";

const ResourceTypeIcon = ({ type }) => {
    
    let icon;
    if (type === "bo") {
        icon = <FiBook />;
    } else if (type === "oc") {
        icon = <LuGraduationCap />;
    } else if (type === "vi"){
        icon = <MdOutlineOndemandVideo />;
    } else if (type === "po"){
        icon = <LuHeadphones />;
    } else if (type === "ar"){
        icon = <GrArticle />;
    } else if (type === "or"){
        icon = <PiGlobe />;
    }

    return (
        <div className='icon'>
            <i className="resource-type-icon">{icon}</i>
        </div>
    );
};

export default ResourceTypeIcon;