import React from 'react';
import { useLocation } from 'react-router-dom';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';

const TabResources = ({
    resources, 
    setResources,
    isFetchingMoreResources,
    hasMoreResources,
    onScroll
    }) => {
        
    const shareView = false; 
    const deleteResource = (id) => {
        setResources(resources.filter(resource => resource.resource.id !== id));
    };
    const updateResource = (updatedResource) => {
        setResources(resources.map(resource => resource.id === updatedResource.id ? updatedResource : resource));
    };

    return (
        <div onScroll={onScroll}>
            <div className={useLocation().pathname.startsWith('/my_library') ? 'library-cards-container' : 'cards-container'}>
                {resources.map((resource) => (
                    <ResourceCard 
                        key={resource.id} 
                        resource={resource} 
                        onDelete={deleteResource}
                        onUpdate={updateResource}
                        shareView={shareView}
                    />
                ))}
            </div>

            {hasMoreResources && isFetchingMoreResources ? 
                <div className='tab-content-loader'>
                    <Loader size={15}/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!isFetchingMoreResources && resources.length === 0 && 
                <p className='no-content-placeholder' style={{marginTop:'30px'}}>No resources yet</p>
            }
            {!hasMoreResources && resources.length >= 10 ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more resources</p>
                :
                <div style={{height:'30px'}}></div>
            }
            
        </div>
    );
};

export default TabResources;