import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../Components/ToastNotification';
import Avatar from 'react-avatar';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import { AiOutlineEdit } from "react-icons/ai";
import { FaRegShareSquare } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { fetchMyData, putUserData } from '@linko/shared_utils';


const NameCard = ({followers, followings, userInfo, handleTabChange}) => {
    const [editingName, setEditingName] = useState(false);
    const [firstName, setFirstName] = useState(userInfo.first_name);
    const [lastName, setLastName] = useState(userInfo.last_name);
    const navigate = useNavigate();
    const { addToast } = useToast();

    const handleNameChange = async (e) => {
        e.preventDefault();
        if (firstName === '' || lastName === '') {
            addToast('Name cannot be empty', {appearance: 'error'});
            setFirstName(userInfo.first_name);
            setLastName(userInfo.last_name);
            return;
        }
        if (firstName === userInfo.first_name && lastName === userInfo.last_name) {
            setEditingName(false);
            return;
        }
        const response = await putUserData(null, firstName, lastName, null);
        if (response.status === 200) {
            addToast('Name updated!', {appearance: 'success'});
            fetchMyData();
        } else {
            addToast('Failed to update name', {appearance: 'error'});
        }
    }

    const copyProfileLink = () => {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(userInfo.profile_link)
            .then(() => {
                addToast('Profile link copied!', {appearance: 'success'});
            })
            .catch(err => {
              console.error('Error writing to clipboard', err);
              addToast('Failed to copy profile link', {appearance: 'error'});
            });
        } else {
          console.error('Clipboard API not supported');
            addToast('Failed to copy profile link', {appearance: 'error'});
        }
    }

    return (
        <div className='name-card'>
            <div className='avatar'>
                <Avatar name={`${userInfo.first_name} ${userInfo.last_name}`} size="50" round={true} color="#48c78e" id='avatar'/>
                <Tippy 
                    interactive={true}
                    content={(
                    <div style={{padding:'8px'}}>
                        <span style={{lineHeight:'1.5', fontSize:'12px'}}>Friends can follow you through the link. They will be able to see your public notes, resources, and knowledge graph. {' '}
                        <span style={{color:'#257953', textDecoration:'underline', fontSize:'12px', cursor: 'pointer'}} onClick={() => window.open(userInfo.profile_link, "_blank")}>Preview Profile</span>
                        </span>
                    </div>
                    )}
                    placement="bottom"
                    arrow={true}
                    theme='heavy-shadow'
                    delay={[0, 0]} 
                >
                    <div className='share' onClick={copyProfileLink}>
                        <FaRegShareSquare />
                        <p>Share Profile</p>
                    </div>
                </Tippy>
            </div>
            <div className='user-name'>
                {editingName ? (
                    <div className='editing'>
                        <div style={{display:'inherit', columnGap:'10px'}}>
                            <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <FaCheck color={'#48c78e'} onClick={(e) => {handleNameChange(e); setEditingName(false)}} style={{cursor:'pointer'}}/>
                    </div>
                ) : (
                    <div className='not-editing'>
                        <h3 className='name'>
                            {firstName}{" "}{lastName}
                        </h3>
                        <AiOutlineEdit className='edit-icon' onClick={() => setEditingName(true)} size={20}/>
                    </div>
                )}
            </div>
            <div style={{display:'flex', columnGap:'5px', marginBottom:'10px'}}>
                <p style={{width:'90px', fontSize:'13px'}}>
                    {userInfo.note_count} Note{userInfo.note_count < 2 ? '' : 's'}
                </p>
                <p style={{width:'90px', fontSize:'13px'}}>
                    {userInfo.resource_count} Resource{userInfo.note_count < 2 ? '' : 's'}
                </p>
            </div>
            <div className='follow' >
                <p onClick={() => handleTabChange('followers')} style={{width:'90px', fontSize:'13px'}}>
                    {followers.length} Follower{followers.length < 2 ? '' : 's'}
                </p>
                <p onClick={() => handleTabChange('followings')} style={{width:'90px', fontSize:'13px'}}>
                    {followings.length} Following{followings.length < 2 ? '' : 's'}
                </p>
            </div>
        </div>
    );
};

export default NameCard;