import React, { useState } from 'react';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import HomePageRight from './HomePageRight';
import YourSubjects from './YourSubjects';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';

import Heatmap from './Heatmap';


const HomePage = () => {

  const [showTextArea, setShowTextArea] = useState(true);
  const [justAddedNote, setJustAddedNote] = useState([]);
  const [notes, setNotes] = useState([]);
  const [library, setLibrary] = useState([]);
  const subjectId = null;
  const noteHint = 'Take a note. You can link it to a resource later.';

  return (
    <div className='page-container'>
      <div className='home-page-left'>
        <AddNoteButton 
          subjectId={subjectId} 
          noteHint={noteHint}
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
          setNotes={setNotes}
          setLibrary={setLibrary}
        />
        <YourSubjects/>
        <TheTabs 
          notes={notes}
          setNotes={setNotes}
          library={library}
          setLibrary={setLibrary}
          subjectId={subjectId} 
          justAddedNote={justAddedNote}
        />
      </div>
      <HomePageRight/>
    </div>
  );
};

export default HomePage;