import React, { useContext, useState } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const SubjectRel = ({ broaderField, siblingFields, childFields }) => {
    const { safeNavigate } = useContext(NavigationPromptContext);
    const [showAllBroader, setShowAllBroader] = useState(false);
    const [showAllSibling, setShowAllSibling] = useState(false);
    const [showAllChild, setShowAllChild] = useState(false);
    const sortedChildFields = childFields.sort((a, b) => b.learn_count - a.learn_count);
    const sortedSiblingFields = siblingFields.sort((a, b) => b.learn_count - a.learn_count);

    const handleClick = (fieldName) => {
        safeNavigate(`/s/${encodeURIComponent(fieldName)}`);
    };

    
    return (
        <div className='subject-rel'>
            <div className='subject-rel-title'>Knowledge Tree</div>
            {broaderField && broaderField.length > 0 && 
            <>
                <h3>BROADER FIELD</h3>
                <div className='subject-rel-list'>
                    {broaderField.map((field, index) => (
                        <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`} onClick={() => handleClick(field.name)}>
                            {field.name}
                            &nbsp;
                            {field.learn_count > 0 && <span style={{height:'20px', width:'20px', display:'inline-block', textAlign:'center', lineHeight:'20px', fontSize:'12px',backgroundColor:'#fff', borderRadius:'50%'}}>{field.learn_count}</span>}
                        </div>
                    ))}
                </div>
            </>
            }
            {sortedSiblingFields && sortedSiblingFields.length > 0 && 
            <>
                <h3>IN THE SAME FIELD</h3>
                <div className='subject-rel-list'>
                    {sortedSiblingFields.slice(0, showAllSibling ? sortedSiblingFields.length : 5).map((field, index) => (
                            <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`} onClick={() => handleClick(field.name)}>
                                {field.name}
                                &nbsp;
                                {field.learn_count > 0 && <span style={{height:'20px', width:'20px', display:'inline-block', textAlign:'center', lineHeight:'20px', fontSize:'12px',backgroundColor:'#fff', borderRadius:'50%'}}>{field.learn_count}</span>}
                            </div>
                        ))}
                    {sortedSiblingFields.length > 5 && (
                        showAllSibling ? 
                            <button className='show-button' onClick={() => setShowAllSibling(false)}>Show less <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/></button>
                            :
                            <button className='show-button' onClick={() => setShowAllSibling(true)}>Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/></button>
                    )}
                </div>
            </>
            }
            {sortedChildFields && sortedChildFields.length > 0 &&
            <>
                <h3>SUBFIELDS</h3>
                <div className='subject-rel-list'>
                    {sortedChildFields.slice(0, showAllChild ? sortedChildFields.length : 5).map((field, index) => (
                        <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`} onClick={() => handleClick(field.name)}>
                            {field.name} 
                            &nbsp;
                            {field.learn_count > 0 && <span style={{height:'20px', width:'20px', display:'inline-block', textAlign:'center', lineHeight:'20px', fontSize:'12px',backgroundColor:'#fff', borderRadius:'50%'}}>{field.learn_count}</span>}
                        </div>
                    ))}
                    {sortedChildFields.length > 5 && (
                        showAllChild ? 
                            <button className='show-button' onClick={() => setShowAllChild(false)}>Show less <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/></button>
                            :
                            <button className='show-button' onClick={() => setShowAllChild(true)}>Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/></button>
                    )}
                </div>
            </>
            }
        </div>
    );
};

export default SubjectRel;