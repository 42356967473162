import React, {useState, useEffect} from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({videoId, setOmitPreview, description}) => {

    const [width, setWidth] = useState(670); 
    const resizeWidth = () => {
        if (window.innerWidth > 1024) {
            setWidth(670);
        } else {
            setWidth(window.innerWidth - 80);
        }
    }
    const height = 0.5625 * width;

    useEffect(() => {
        resizeWidth();
        window.addEventListener('resize', resizeWidth);
        return () => {
            window.removeEventListener('resize', resizeWidth);
        }
    }, []);
    
    return (
        <>
        <YouTube
            videoId={videoId}
            opts={{
                width: width,
                height: height,
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    rel: 0,
                    showinfo: 0
                }
            }}
            onError={() => setOmitPreview(true)}
            />
            {description && <div style={{marginTop:'30px', width:'100%'}}>
                {description}
            </div>}  
        </>
    );
};

export default VideoPlayer;