import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { FollowContext } from '../../Context/AppContext';
import { GoPersonAdd } from "react-icons/go";
import { handleFollowAction } from '@linko/shared_utils';


const FollowRequest = ({notifications}) => {

    const [newNotifications, setNewNotifications] = useState(notifications);
    const [requestAccepted, setRequestAccepted] = useState(new Array(notifications.length).fill(false));
    const { fetchFollowData } = useContext(FollowContext);
    const navigate = useNavigate();

    const handleAccept = async (id) => {
        const response = await handleFollowAction(id, 'confirm');
        if (response.status === 200) {
            setRequestAccepted(prevState => {
                const newState = [...prevState];
                newState[id] = true;
                return newState;
              });
            setTimeout(() => {
                setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            }, 5000);
            fetchFollowData();
        } else {
            window.alert('There was an error accepting the request, please try again later.');
        }
    }

    const handleDelete = async (id) => {
        const response = await handleFollowAction(id, 'delete');
        if (response.status === 200) {
            setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            fetchFollowData();
        } else {
            window.alert('There was an error deleting the request, please try again later.');
        }
    }

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        navigate(`/profile/${initial}${profileCode}`);
    };

    return (
        <div className='notifications'>
        
            <h2 className='follow-title'>Follow Request</h2>
            {(newNotifications && newNotifications.length === 0) ? 
                <h2 style={{fontStyle:'italic', fontSize:'16px', color:'#8f8f93'}} className='notification-group'>No follow request</h2>
                :
                <>
                {newNotifications.map((notifications) => {
                    return (
                        <div className='notification-group' key={notifications.id}>
                            <div className='follow-request-icon'>
                                <GoPersonAdd size={25} />
                            </div>
                            <p>
                                <span className='name-span' onClick={goToProfile.bind(this, notifications.id, notifications.first_name, notifications.last_name)}>
                                {notifications.first_name} {notifications.last_name} 
                                </span>
                                {' '}requested to follow you. 
                            </p>
                            {requestAccepted[notifications.id] ? 
                            <p className='action-buttons' style={{fontSize:'14px'}}>Request accepted</p>
                            :
                            <div className='action-buttons'>
                                <button className='accept' onClick={(event) => handleAccept(notifications.id, event)}>Accept</button>
                                <button className='delete-notif' onClick={() => handleDelete(notifications.id)}>Delete</button>
                            </div>
                            }
                        </div>
                    );
                })}
                </>
            }
        </div>
    );
};

export default FollowRequest;