import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const searchBooks = async (searchTerm) => {

  try {
    const accessToken = storageUtils.getItem('access_token');
    const response = await axios.get(`/api/search/search_book/?q=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.items || [];
  } catch (error) {
    console.error(
      "Error fetching books:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const searchAuthorBooks = async (title, author) => {

  const resourceTitle =  title.split(":")[0];
  
  try {
    const accessToken = storageUtils.getItem('access_token');
    const response = await axios.get(`/api/search/search_book/?author=${author}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const filteredItems = response.data.items.filter(item => {
      const itemTitle = item.volumeInfo.title.split(":")[0];
      return itemTitle !== resourceTitle;
    });
    return filteredItems || [];
  } catch (error) {
    console.error(
      "Error fetching books:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};