import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { activateAccount } from '@linko/shared_utils';

function ActivateAccount() {
    const { uidb64, token } = useParams();
    const [activationStatus, setActivationStatus] = useState('');
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        activateAccount(uidb64, token)
        .then(res => {
            if (res.data.detail === "Email verified") {
                setActivationStatus('Your account has been activated.');
                localStorage.setItem('successNotice', 'Your account has been activated.');
                navigate('/login');
            } else { 
                setIsError(true);
                setActivationStatus('The link has expired. A new activation link has been sent to your email.');
            }
        })
        .catch((error) => {
            setIsError(true);
            setActivationStatus('Activation link is invalid or has expired.');
        });
    }, [uidb64, token]);

  return (
    <div className="login">
      <div className="login-form-container">
        {activationStatus}
      </div>
     </div>
  );
}

export default ActivateAccount;
