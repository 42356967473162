import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { AppContextProvider } from './Context/AppContext';
import { ModalProvider } from './Context/ModalContext';
import { NavigationProvider } from './Context/NavigationContext';
import { ToastProvider } from './Components/ToastNotification';
import './Stylesheets/index.scss';
import PrivateRoute from './Context/PrivateRoute';
import NavBar from './Components/NavAndFooter/NavBar';
import Footer from './Components/NavAndFooter/Footer';
// Auth pages
import Login from './Pages/AuthPages/Login';
import Register from './Pages/AuthPages/Register';
import CongratsRegistered from './Pages/AuthPages/CongratsRegistered';
import ForgetPassword from './Pages/AuthPages/ForgetPassword';
import ActivateAccount from './Pages/AuthPages/ActivateAccount';
import ResetPassword from './Pages/AuthPages/ResetPassword';
// App pages
import MyLibrary from './Pages/MyLibrary/MyLibrary';
import ResourcePage from './Pages/ResourcePage/ResourcePage';
import NotePage from './Pages/NotePage/NotePage';
import HomePage from './Pages/HomePage/HomePage';
import SubjectPage from './Pages/SubjectPage/SubjectPage';
import Landing from './Pages/LandingPage/Landing';
import AccountPage from './Pages/AccountSettingPage/AccountPage';
// Public pages·
import PublicProfile from './Pages/PublicProfilePages/PublicProfile';
import NoteForExport from './Components/NoteCard/NoteForExport';
// Policy pages
import PrivacyPolicy from './Pages/PolicyPages/PrivacyPolicy';
import TermOfService from './Pages/PolicyPages/TermOfService';
import CookiePolicy from './Pages/PolicyPages/CookiePolicy';
import KnowledgeGraphExplained from './Pages/PolicyPages/KnowledgeGraphExplained';


function App() {

  return (
    <>
    <Router>
      <ModalProvider><ToastProvider>
        <AppContextProvider>
          <NavigationProvider>
          <div id="all-content">
            <NavBar/>       
            <div id='mainContent'>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path='/*' element={<Navigate to='/my_linko' />} />
                {/* Auth Routes */}
                <Route path="/activate/:uidb64/:token" element={<ActivateAccount />} />
                <Route path="/reset/:uidb64/:token" element={<ResetPassword />} />
                <Route path="/login" element={<Login />}/>
                <Route path="/register" element={<Register />} />
                <Route path="/thank_you_for_registering" element={<CongratsRegistered />} />
                <Route path="/forget_password" element={<ForgetPassword />} />
                <Route path="/reset_password/:uidb64/:token" element={<ResetPassword />} />
                {/* App Routes */}
                {/* <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} /> */}
                <Route path="/my_library" element={<PrivateRoute><MyLibrary /></PrivateRoute>} />
                <Route path="/r/:id" element={<PrivateRoute><ResourcePage /></PrivateRoute>} />
                <Route path="/my_linko" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                <Route path="/s/:subjectName" element={<PrivateRoute><SubjectPage /></PrivateRoute>} />
                <Route path="/account/:username" element={<PrivateRoute><AccountPage /></PrivateRoute>} />
                <Route path="/n/:encodedNoteId" element={<NotePage />} />
                {/* Public Pages */}
                <Route path="/profile/:userCode" element={<PublicProfile />} />
                {/* <Route path="/note/:encodedNoteId" element={<NoteForExport />} /> */}
                {/* Policies */}
                <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
                <Route path="/term_of_service" element={<TermOfService/>} />
                <Route path="/cookie_policy" element={<CookiePolicy/>} />
                <Route path="/knowledge_graph_explained" element={<KnowledgeGraphExplained/>} />
            </Routes>
            </div>
            <div id="footer-container" >
              <Footer />
            </div>
          </div>
          </NavigationProvider>
        </AppContextProvider>
      </ToastProvider></ModalProvider>
    </Router>
    </>
  );
  }



export default App;
