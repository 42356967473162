import React, {useState, useEffect, useContext} from 'react';
import { UserSubjectContext } from '../../Context/AppContext';

const LibrarySubjectFilter = ({
    filterSubject, 
    setFilterSubject,
    activeTab,
}) => {
    const { userSubject } = useContext(UserSubjectContext);
    const resourceTopSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.resource_count - a.resource_count);
    const noteTopSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.note_count - a.note_count);
    const myTags = userSubject.filter(item => item.is_linked === false).sort((a, b) => b.resource_count - a.resource_count); 
    const topSubject = activeTab === 'notes' ? noteTopSubject : resourceTopSubject;
    const [showAll, setShowAll] = useState(false);

    const handleSubjectFilterClick = (subjectId) => {
        if (filterSubject.includes(subjectId)) {
            setFilterSubject(filterSubject.filter(k => k !== subjectId));
        } else {
            setFilterSubject([...filterSubject, subjectId]);
        }
    };

    return (
        <div className='library-subject-filter'>
            {myTags.map((subject) => (
                <div key={subject.id} className={`cus-label ${filterSubject.includes(subject.id) ? 'active' : 'inactive'}`} onClick={() => handleSubjectFilterClick(subject.id)}>
                    {subject.name}
                </div>
            ))}
            {topSubject.slice(0, showAll ? topSubject.length : 10).map((subject) => (
                <div key={subject.id} className={`subject-tag ${filterSubject.includes(subject.id) ? 'studied' : 'not-studied'}`} onClick={() => handleSubjectFilterClick(subject.id)}>
                    {subject.name}
                </div>
            ))}
            {topSubject.length > 10 && ( 
                showAll ? 
                    <button className='show-button' onClick={() => setShowAll(false)}>Show Less</button>
                    :
                    <button className='show-button' onClick={() => setShowAll(true)}>Show All</button>
            )}
        </div>
    );
};

export default LibrarySubjectFilter;