import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useLocation } from 'react-router-dom';
// Components
import SelectNoteView from './SelectNoteView';
import TabNotes from './TabNotes';
import TabLibrary from './TabLibrary';
import TabExplore from './TabExplore';
import TabRevisit from './TabRevisit';
import NoContentPlaceholder from './NoContentPlaceholder';
// Functions
import { handleScrollEvent, setupScrollListeners } from './handleScroll';
import { setupTabs } from './tabManagement';
import { fetchNotes, fetchMyFeed, fetchFollowingFeed, fetchRevisitData } from '@linko/shared_utils'

const TheTabs = ({
        notes,
        setNotes,
        library,
        setLibrary,
        subjectId,
        publicField,
        resourceId, 
        noteId, 
        resourceType, 
    }) => {

    const location = useLocation();
    const { userInfo } = useContext(UserContext);
    const resourcePage = location.pathname.startsWith('/r/');
    const homePage = location.pathname.startsWith('/my_linko');
    const subjectPage = location.pathname.startsWith('/s/');
    const notePage = location.pathname.startsWith('/n/');
    const [activeTab, setActiveTab] = useState((resourcePage || notePage) ? 'notes' : 'library');
    const [tabNames, setTabNames] = useState({
        first: null,
        second: null,
      });

    // Notes
    const [notesOffset, setNotesOffset] = useState(0);
    const [isFetchingMoreNotes, setIsFetchingMoreNotes] = useState(false);
    const [hasMoreNotes, setHasMoreNotes] = useState(true);
    const shuffledNotes = homePage ? true : false;
    // pseudo Resources
    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    // Explore
    const [explore, setExplore] = useState([]);
    const [ExploreOffset, setExploreOffset] = useState(0);
    const [isFetchingMoreExplore, setIsFetchingMoreExplore] = useState(false);
    const [hasMoreExplore, setHasMoreExplore] = useState(true);
    // Library
    const [libraryOffset, setLibraryOffset] = useState(0);
    const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(false);
    const [hasMoreLibrary, setHasMoreLibrary] = useState(true);
    // Revisit
    const [revisitSubject, setRevisitSubject] = useState('');
    const [revisitNote, setRevisitNote] = useState(null);
    const [isShufflingSubject, setIsShufflingSubject] = useState(true);
    
    const limit = 10;

    // Dynamically change active tab based on content length
    useEffect(() => {
        if (resourcePage) {
            if (notes?.length === 0 && !isFetchingMoreNotes && explore?.length !== 0 && !isFetchingMoreExplore) {
                setActiveTab('explore');
            } else {
                setActiveTab('notes');
            }
        }
        if (subjectPage && publicField) {
            if (library?.length === 0 && !isFetchingMoreLibrary && explore?.length !== 0 && !isFetchingMoreExplore) {
                setActiveTab('explore');
            } else {
                setActiveTab('library');
            }
        }
        if (subjectPage && !publicField) {
            setActiveTab('library');
        }
    }, [resourcePage, subjectPage, isFetchingMoreNotes, isFetchingMoreLibrary]);

    useEffect(() => {
        if (homePage && userInfo) {
            if (userInfo?.note_count > 10) {
                setActiveTab('revisit');
            } else {
                setActiveTab('library');
            }
        }
    }, [homePage, userInfo]);

    useEffect(() => {
        setNotes([]);
        setNotesOffset(0);
    }, [subjectId, resourceId, noteId]);

    useEffect(() => {
        if (setNotes) {
            setNotes([]);
            setNotesOffset(0);
        }
        if (setLibrary) {
            setLibrary([]);
            setLibraryOffset(0);
        }
        if (setExplore) {
            setExplore([]);
            setExploreOffset(0);
        }
        if (homePage) {
            setIsFetchingMoreExplore(true);
            setIsFetchingMoreLibrary(true);
        } else if (resourcePage) {
            setIsFetchingMoreNotes(true);
            setIsFetchingMoreExplore(true);
        } else if (subjectPage && subjectId !== null) {
            if (publicField) {
                setIsFetchingMoreLibrary(true);
                setIsFetchingMoreExplore(true);
            } else {
                setIsFetchingMoreLibrary(true);
            }
        } else if (notePage) {
            setIsFetchingMoreNotes(true);
        }
    }, [userInfo, location.pathname, subjectId, resourceId, noteId]);

    useEffect(() => {
        if (isFetchingMoreNotes) {
            fetchNotes(limit, notesOffset, resourceId, noteId, notes, setNotes, setNotesOffset, setHasMoreNotes, setIsFetchingMoreNotes, [], null, notePage);
        }
    }, [isFetchingMoreNotes]);

    useEffect(() => {
        if (isFetchingMoreExplore) {
            fetchFollowingFeed(limit, ExploreOffset, subjectId, resourceId, setExplore, setExploreOffset, setHasMoreExplore, setIsFetchingMoreExplore, resourceId, subjectId);
        }
    }, [isFetchingMoreExplore]);

    useEffect(() => {
        if (isFetchingMoreLibrary) {
            fetchMyFeed(limit, libraryOffset, subjectId, null, setLibrary, setLibraryOffset, setHasMoreLibrary, setIsFetchingMoreLibrary);
        }
    }, [isFetchingMoreLibrary]);

    useEffect(() => {
        if (isShufflingSubject && userInfo?.note_count >= 10) {
          fetchRevisitData(setRevisitSubject, setRevisitNote, setIsShufflingSubject);
        }
    }, [isShufflingSubject, userInfo?.note_count]);

    useEffect(() => {
        const cleanupScrollListeners = setupScrollListeners(handleScrollEvent, activeTab, setIsFetchingMoreNotes, setIsFetchingMoreResources, setIsFetchingMoreExplore, setIsFetchingMoreLibrary, hasMoreNotes, hasMoreResources, hasMoreExplore, hasMoreLibrary);
        return () => {
            cleanupScrollListeners();
        };
    }, [activeTab, hasMoreNotes, hasMoreExplore, hasMoreLibrary]);

    useEffect(() => {
        setupTabs(location, setTabNames);
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab]);

    useEffect(() => {
        let lastScrollTop = 0;
        const handleScroll = () => {
          const navbar = document.querySelector('.tabs-wrapper');
          const currentScroll = window.scrollY || document.documentElement.scrollTop;
          if (navbar) {
            if (currentScroll > lastScrollTop) {
              // Scrolling down
              navbar.style.top = '0px'; 
            } else {
              // Scrolling up
              navbar.style.top = '70px';
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []); 

    return (
        <div className='the-tabs'>
            <div className="tabs-wrapper">
                <div className='tabs'>
                    {(resourcePage || notePage) &&
                        <button
                            className={`tab-button ${activeTab === 'notes' ? 'active' : ''}`}
                            onClick={() => setActiveTab('notes')}
                        >
                            {tabNames.first}
                        </button>
                    }
                    {(homePage || subjectPage) &&
                        <button
                            className={`tab-button ${(activeTab === 'library' || (activeTab === 'revisit' && shuffledNotes))? 'active' : ''}`}
                            onClick={() => homePage ? setActiveTab('revisit') : setActiveTab('library')}
                        >
                            {tabNames.first}
                        </button>
                    }
                    {(publicField || resourcePage || homePage) && 
                        <button
                            className={`tab-button ${activeTab === 'explore' ? 'active' : ''}`}
                            onClick={() => setActiveTab('explore')}
                        >
                            {tabNames.second}
                        </button>
                    }
                </div>

                {(activeTab ==='library' || activeTab === 'revisit')&& location.pathname === '/my_linko' &&
                    <SelectNoteView
                        shuffledNotes={shuffledNotes} 
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                }
            </div>
            <div className="tab-content">
            {activeTab === 'notes' ? (
                <div id='tab-notes' 
                    className={`tab ${activeTab === 'notes' || activeTab === 'library' ? 'active' : 'hidden'}`} 
                    >
                    <TabNotes 
                        subjectId={subjectId} 
                        notes={notes}
                        setNotes={setNotes}
                        setLibrary={setLibrary}
                        isFetchingMoreNotes={isFetchingMoreNotes}
                        hasMoreNotes={hasMoreNotes}
                    />
                </div>
            ) : activeTab === 'revisit' ? (
                <div id='tab-revisit' 
                    className={`tab ${activeTab === 'revisit' ? 'active' : 'hidden'}`} >
                    {userInfo?.note_count >= 10 ?
                        <TabRevisit 
                            revisitSubject={revisitSubject}
                            revisitNote={revisitNote}
                            setRevisitNote={setRevisitNote}
                            isShufflingSubject={isShufflingSubject}
                            setIsShufflingSubject={setIsShufflingSubject}
                        />
                        :
                        <NoContentPlaceholder activeTab={'revisit'}/>
                    }
                </div>
            ) : activeTab === 'library' ? (
                <div id='tab-library' 
                    className={`tab ${activeTab === 'library' ? 'active' : 'hidden'}`}
                    style={{ display: activeTab === 'library' ? 'block' : 'none' }}>
                    <TabLibrary
                        library={library}
                        setLibrary={setLibrary}
                        setNotes={setNotes}
                        isFetchingMoreLibrary={isFetchingMoreLibrary}
                        hasMoreLibrary={hasMoreLibrary}
                    />
                </div>
            ) : activeTab === 'explore' ? (
                <div id='tab-explore' 
                    className={`tab ${activeTab === 'explore' ? 'active' : 'hidden'}`} 
                    style={{ display: activeTab === 'explore' ? 'block' : 'none' }}>
                <TabExplore
                    explore={explore}
                    setExplore={setExplore}
                    isFetchingMoreExplore={isFetchingMoreExplore}
                    hasMoreExplore={hasMoreExplore}
                    type={resourceType ? resourceType : 'resource'}
                    resourceId={resourceId}
                    subjectId={subjectId}
                />
                </div>
            ) : null}
            </div>
        </div>
    );
};

export default TheTabs;
