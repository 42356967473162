import React, { useContext, useState } from 'react';
import { UserSubjectContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
 
const SubjectOverview = () => {
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { userSubject, userFans } = useContext(UserSubjectContext);
    const myTags = userSubject.filter(item => item.is_linked === false).sort((a, b) => b.learn_count - a.learn_count);
    const topSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.learn_count - a.learn_count).slice(0, 15);
    const [showMyTags, setShowMyTags] = useState(false);
    const [showTopSubject, setShowTopSubject] = useState(false);

    const handleClick = (fieldName) => {
        safeNavigate(`/s/${encodeURIComponent(fieldName)}`);
    };

    return (
        <div className='subject-rel'>
        {userFans &&
        <>
            <div className='subject-rel-title'>Overview</div>
            <div className='subject-rel-list'>
                {userFans.map((field) => (
                    <div key={field.id} className='subject-tag studied' onClick={() => handleClick(field.name)}>
                        {field.name}
                        &nbsp;
                        <span className='subject-tag-learn-count'>{field.count}</span>
                    </div>
                ))}
            </div>
        </>
        }
        {topSubject && topSubject.length > 0 &&
        <>
            <div className='subject-rel-title'>My Top Learnings</div>
            <div className='subject-rel-list'>
                {topSubject.filter(field => field.learn_count > 0).slice(0, showTopSubject ? topSubject.length : 10).map((field, index) => (
                    <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`} onClick={() => handleClick(field.name)}>
                        {field.name}
                        {/* &nbsp;
                        {field.learn_count > 0 && <span className='subject-tag-learn-count'>{field.learn_count}</span>} */}
                    </div>
                ))}
                {topSubject.filter(field => field.learn_count > 0).length > 10 && ( 
                    showTopSubject? 
                        <button className='show-button' onClick={() => setShowTopSubject(false)}>Show Less</button>
                        :
                        <button className='show-button' onClick={() => setShowTopSubject(true)}>Show All</button>
                )}
            </div>
        </>
        }
        {myTags && myTags.length > 0 && 
        <>
            <div className='subject-rel-title'>My Tags</div>
            <div className='subject-rel-list my-tags'>
                {myTags.slice(0, showMyTags ? myTags.length : 10).map((tag, index) => (
                    <div key={index} className='my-tag' style={{color:'#F27215'}} onClick={() => safeNavigate(`/s/${encodeURIComponent(tag.name)}`)}>
                        {tag.name}
                    </div>
                ))}
                {myTags.length > 10 && (
                    showMyTags? 
                        <button className='show-button' onClick={() => setShowMyTags(false)}>Show Less</button>
                        :
                        <button className='show-button' onClick={() => setShowMyTags(true)}>Show All</button>
                )}
            </div>
        </>
        }
        </div>
    );
};

export default SubjectOverview;