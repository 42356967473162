import React, {useEffect, useState} from 'react';
import LinkResourceModal from './LinkResourceModal';
import { MdOutlineLibraryAdd } from "react-icons/md";

const LinkedResource = ({noteResource, setNoteResource, noteId, updateSingleNote}) => {
    const [hasLinkedResource, setHasLinkedResource] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (noteResource && noteResource.id) {
            setHasLinkedResource(true);
        }
    }, [noteResource]);

    const closeModal = () => setIsModalOpen(false);

    return (
        <div className='linked-resource'>
            <button className='link-resource-button linko-button linko-button--cancel' onClick={() => setIsModalOpen(true)}>
                <MdOutlineLibraryAdd size={20}/>
                {hasLinkedResource ? 
                    <span className='note-edit-resource-title'>{noteResource?.title}</span>
                : 
                    <span>Link a resource</span>
                }
            </button>
            {isModalOpen && (
                <div className='linko-button linko-button--cancel'>
                    <LinkResourceModal 
                        onClose={closeModal} 
                        setNoteResource={setNoteResource} 
                        noteId={noteId}
                        updateSingleNote={updateSingleNote}
                    />
                </div>
            )}
        </div>
    );
};

export default LinkedResource;