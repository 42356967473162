import React, { createContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from './ModalContext.jsx';

export const NavigationPromptContext = createContext();

export const NavigationPromptProvider = ({ children }) => {

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { requestConfirm } = useModal();

  // Use an effect to listen for leaving the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault(); // Standard technique for prompting the user
        event.returnValue = ''; // Chrome requires returnValue to be set
      }
    };

    // Add event listener for the browser's window close, refresh, or navigate away
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // Safe navigation function
  const safeNavigate = (path, options = {}) => {
    if (path === location.pathname) return;
    if (hasUnsavedChanges) {
      requestConfirm(
        'You have unsaved changes. Are you sure you want to leave?', 
        () => {
          setHasUnsavedChanges(false); 
            navigate(path, options);
            window.scrollTo(0, 0);
        }
      );
    } else {
      navigate(path, options);
      window.scrollTo(0, 0);
    }
  };

  return (
    <NavigationPromptContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges, safeNavigate }}>
      {children}
    </NavigationPromptContext.Provider>
  );
};
