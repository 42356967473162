import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '../ToastNotification';
import { useModal } from '../../Context/ModalContext';
// Icons
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdMoreVert } from "react-icons/md";
import { deleteResource } from "@linko/shared_utils";

const ResourceCardActions = ({ 
    link, 
    id, 
    onDelete, 
    isBook, 
    shareView, 
    handleAddToLibrary,
    currentResource,
    }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { addToast, setPersistentMessage } = useToast();
    const { requestConfirm, requestAlert } = useModal();

    const handleDeleteResource = async () => {
        requestConfirm(
            'Are you sure you want to delete this resource?',
            async () => {
                await deleteResource(id);
                onDelete(id); 
                if (location.pathname === '/r/') {
                    navigate('/my_linko');
                    setPersistentMessage('Resource deleted.', {appearance: 'success'});
                } else {
                    addToast('Resource deleted.', {appearance: 'success'});
                }
            },
            null,
            'Delete'
        );
    }

    if (currentResource) {
        return (
            <div className='card-action-laid'>
                {!isBook &&
                    <div className='card-action-group' onClick={() => {window.open(link); setIsDropdownOpen(false);}}>
                        <HiOutlineExternalLink className='card-action-icon' />
                        <p>Go to resource</p>
                    </div>                       
                }
                {!shareView ? 
                    <div className='card-action-group delete-group' onClick={() => {handleDeleteResource(id); setIsDropdownOpen(false);}}>
                        <FaRegTrashAlt className='card-action-icon delete-icon' />
                        <p>Delete</p>
                    </div>
                : 
                    <div className='card-action-group' onClick={() => {handleAddToLibrary(); setIsDropdownOpen(false);}}>
                        <MdOutlineLibraryAdd className='card-action-icon' />
                        <p>Add to My Library</p>
                    </div>
                }
            </div>
        );
    }

    return (
        <div>
            <div style={{height:'fit-content'}}
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
            >
                <MdMoreVert className="MoreTabWLink" onClick={() => setIsDropdownOpen(true)} role='moreicon'/>
                {isDropdownOpen && (
                    <div ref={dropdownRef} className='card-action-dropdown'>
                        {!isBook &&
                            <div role='action-group' className='card-action-group' onClick={() => {window.open(link); setIsDropdownOpen(false);}}>
                                <HiOutlineExternalLink className='card-action-icon' />
                                <p>Go to resource</p>
                            </div>                       
                        }
                        {!shareView ? 
                            <div role='action-group' className='card-action-group delete-group' onClick={() => {handleDeleteResource(id); setIsDropdownOpen(false);}}>
                                <FaRegTrashAlt className='card-action-icon delete-icon' />
                                <p>Delete</p>
                            </div>
                        : 
                            <div role='action-group' className='card-action-group' onClick={() => {handleAddToLibrary(); setIsDropdownOpen(false);}}>
                                <MdOutlineLibraryAdd className='card-action-icon' />
                                <p>Add to My Library</p>
                            </div>
                        }
                    </div>
                )}
            </div>    
        </div>
    );
};

export default ResourceCardActions;
