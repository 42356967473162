import React from 'react';
import AddBook from './AddBook';
import AddLink from './AddLink';

const AddToLinkoModal = ({ setSelectedResource, content, onClose }) => {
    let ComponentToRender;
    switch(content) {
        case 'Book':
            ComponentToRender = AddBook;
            break;
        case 'Link':
            ComponentToRender = AddLink; 
            break;
        default:
            ComponentToRender = null;
    }

    return (
        <div className="add-to-linko-modal">
            {ComponentToRender && <ComponentToRender setSelectedResource={setSelectedResource} onClose={onClose}/>}
        </div>
    );
};

export default AddToLinkoModal;
