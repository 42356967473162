import axios from "../Middleware/axios.js";
import storageUtils from "../storageUtils.js";

export const handleLogout = async () => {
    try {
        const refreshToken = await storageUtils.getItem("refresh_token");
        await axios.post("/api/logout/", 
            { refresh_token: refreshToken },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await storageUtils.getItem("access_token")}`
                },
            }
        );
        delete axios.defaults.headers.common["Authorization"];
        await storageUtils.clear();
    } catch (e) {
        console.error("Logout error:", e);
        delete axios.defaults.headers.common["Authorization"];
        await storageUtils.removeItem("access_token");
        await storageUtils.removeItem("refresh_token");
    }
}   