import React from 'react';

const FocusModeNav = () => {
    return (
        <div id="navbar-container" style={{backgroundColor: '#fff'}}>
        <nav className="linko-navbar" role="navigation" aria-label="main navigation">
      {/* Large */}
        <div className="linko-navbar-brand">
            <h1 className='logo'>
            Linko
            </h1>
            <p id="beta">
            Beta
            </p>
            <p className="slogan">
            Learning as a lifestyle
            </p>
        </div>
      </nav>
        </div>
    );
};

export default FocusModeNav;