import React, {useState} from 'react';
import FollowList from './FollowList';

const Followings = ({ followings}) => {
    const [followList, setFollowList] = useState(followings);

    return (
        <FollowList followList={followings} setFollowList={setFollowList} followType='Following' />
    );
};

export default Followings;