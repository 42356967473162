import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

const getAuthHeader = async () => {
    const accessToken = await storageUtils.getItem("access_token");
    if (!accessToken) {
        throw new Error('No access token found');
    }
    return { Authorization: `Bearer ${accessToken}` };
};

export const postResourceAndLearn = async (bookId, link) => {
    try {
        const headers = await getAuthHeader();
        const response = await axios.post(
            "/api/resource/create_and_learn/",
            {
                book_id: bookId || null,
                link: link || null
            },
            { headers }
        );
        return response;
    } catch (error) {
        console.error('Error posting resource and learn:', error);
        throw error;
    }
};

export const postResourceToMine = async (resourceId) => {
    try {
        const headers = await getAuthHeader();
        const response = await axios.post(
            `/api/resource/${resourceId}/user_learn/`,
            { headers }
        );
        return response;
    } catch (error) {
        console.error('Error posting resource to mine:', error);
        throw error;
    }
};

export const postNewResource = async (bookId, link) => {
    try {
        const headers = await getAuthHeader();
        const response = await axios.post(
            "/api/resource/",
            {
                book_id: bookId || null,
                link: link || null
            },
            { headers }
        );
        return response;
    } catch (error) {
        console.error('Error posting new resource:', error);
        throw error;
    }
};
