export const handleScrollEvent = (event, activeTab, setIsFetchingMoreNotes, setIsFetchingMoreResources,setIsFetchingMoreExplore, setIsFetchingMoreLibrary, hasMoreNotes, hasMoreResources, hasMoreExplore, hasMoreLibrary) => {

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {

        switch (activeTab) {
            case 'notes':
                if (hasMoreNotes) {
                    setIsFetchingMoreNotes(true);
                }
                break;
            case 'resources':
                if (hasMoreResources) {
                    setIsFetchingMoreResources(true);
                }
                break;
            case 'explore':
                if (hasMoreExplore) {
                    setIsFetchingMoreExplore(true);
                }
                break;
            case 'library':
                if (hasMoreLibrary) {
                    setIsFetchingMoreLibrary(true);
                }
                break;
            default:
                break;
        }
    }
};

export const setupScrollListeners = (handleScrollEvent, activeTab, setIsFetchingMoreNotes, setIsFetchingMoreResources, setIsFetchingMoreExplore, setIsFetchingMoreLibrary, hasMoreNotes, hasMoreResources, hasMoreExplore, hasMoreLibrary) => {
    function onScroll(event) {
        handleScrollEvent(event, activeTab, setIsFetchingMoreNotes, setIsFetchingMoreResources, setIsFetchingMoreExplore, setIsFetchingMoreLibrary, hasMoreNotes, hasMoreResources, hasMoreExplore, hasMoreLibrary);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
        window.removeEventListener('scroll', onScroll);
    };
};

