import React, {useState, useEffect, useContext, useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/AppContext";
import { RxDividerVertical } from "react-icons/rx"; 
import ResourceCardActions from "./ResourceCardActions";
import ResourceTypeIcon from "./ResourceTypeIcon";
import ScrollToTopLink from "../ScrollToTopLink";
import ResourceProgress from "./ResourceProgress";
import CardSubject from "../CardSubject";
import { postResourceToMine } from "@linko/shared_utils";

const ResourceCard = ({ resource , onDelete, onUpdate,  shareView, currentResource}) => {
    let id, title, author, type, description, month, year, url, platform, image_link;
    if (resource && resource.resource) {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource.resource);
    } else {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource);
    }
    const resourceCardRef = useRef(null);
    const [subject, setSubject] = useState([]);
    const isBook = type === "bo";
    const imageLink = image_link && image_link.trim() !== "" 
        ? image_link.startsWith("http:")
            ? "https:" + image_link.substring(5)
            : image_link
        : null;
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const [hideImage, setHideImage] = useState(false);
    
    useEffect(() => {
        if (resource?.user !== null) {
            setSubject(resource.user_knowledge);
        } else {
            setSubject(resource.resource.knowledge);
        }
    }, [resource, userInfo]);

    const handleAddToLibrary = async () => {
        postResourceToMine(id).finally(() => {
            if (location.pathname.startsWith("/r/")) 
            { window.location.reload(); }
            else navigate(`/r/${id}`);
        });
    }

    // Hide image when resource card is small
    useEffect(() => {
        const checkWidth = () => {
            if (resourceCardRef.current) {
                setHideImage(resourceCardRef.current.offsetWidth < 400);
            }
        };
        checkWidth();
        window.addEventListener('resize', checkWidth);

        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    return (
        <div id="resource-card" ref={resourceCardRef}>
            <div className="resource-card-upper">
                <ResourceTypeIcon type={type} />
                <div className="resource-card-upper-right">
                    <div className="resource-info">
                        <div className="resource-text-info">
                            <div className="resource-title-author">
                                <ScrollToTopLink
                                    to={`/r/${id}`}
                                    rel="noreferrer"
                                >
                                    <p className={currentResource ? 'no-hover' : 'resource-title'}>{title}</p>
                                </ScrollToTopLink>
                                <div className="author">
                                    {author && <span style={{display:'inline', whiteSpace:'nowrap'}}>{author}</span>}
                                    {author && year && <span style={{display:'inline', whiteSpace:'nowrap'}}>, {year}</span>}
                                    {author && year && month && <span style={{display:'inline', whiteSpace:'nowrap'}}>/ {month.toString().padStart(2, '0')}</span>}
                                    {platform && platform !== 'null' && 
                                        <span style={{display:'inline-flex', alignItems:'center', whiteSpace:'nowrap'}}>
                                            {author && <span className='rx-divider-vertical' style={{display:'inline-block'}}></span>}
                                            <span style={{display:'inline-block'}}>, {platform}</span>
                                        </span>
                                    }
                                </div>
                            </div>
                            {!shareView && <ResourceProgress id={id} learnHistory={resource} onUpdate={onUpdate}/>}
                            <CardSubject
                                subject={subject}
                                setSubject={setSubject}
                                shareView={shareView}
                                resource={resource}
                                resourceId={id}
                                onUpdate={onUpdate}
                            />
                            {currentResource && 
                                <ResourceCardActions
                                    link={url}
                                    id={id}
                                    onDelete={onDelete} 
                                    isBook={isBook}
                                    shareView={shareView}
                                    handleAddToLibrary={handleAddToLibrary}
                                    currentResource={currentResource}
                                />
                            }
                        </div>
                        {imageLink !== null && !hideImage && (
                            <div className="resource-img">
                                <img
                                    src={imageLink}
                                    alt={title}
                                    className="the-img"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!currentResource && 
                    <ResourceCardActions
                        link={url}
                        id={id}
                        onDelete={onDelete}
                        isBook={isBook}
                        shareView={shareView}
                        handleAddToLibrary={handleAddToLibrary}
                        currentResource={currentResource}
                    />
                }
            </div>
        </div>
    );
};

export default ResourceCard;
