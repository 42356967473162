import React, { useState, useEffect, useContext } from 'react'
import { LuTrophy } from "react-icons/lu";
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { FollowContext, UserContext } from '../../Context/AppContext';
import { GoPersonAdd, GoPeople } from "react-icons/go";
import { sendFollowRequest } from '@linko/shared_utils';
import Tippy from '@tippyjs/react';
import { useToast } from '../../Components/ToastNotification';

export default function LeaderBoard({topUsers}) {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const { followings } = useContext(FollowContext);
    const { userInfo } = useContext(UserContext);
    const { setPersistentMessage } = useToast(); 

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        safeNavigate(`/profile/${initial}${profileCode}`);
    };

    const requestFollow = async (userId) => {
        await sendFollowRequest(userId);
        setPersistentMessage('Follow request sent');
    }


    if (topUsers && topUsers.length === 0) return null;

    return (
        <div style={{marginBottom:'10px'}}>
            <div className='subject-rel-title'>Leaderboard</div>
            {topUsers && topUsers.map((user, index) => (
                <div className='leaderboard-user-container' key={user.id}>
                    <LuTrophy size={24} color={index === 0 ? '#FFD700' : index === 1 ? '#C0C0C0' : '#D9A87A'} />
                    <div className='leaderboard-user-name hover-green' onClick={() => goToProfile(user.id, user.first_name, user.last_name)}>
                        {user.first_name} {user.last_name}
                    </div>
                    <div className='leaderboard-user-score'>
                        {user.note_count} note{user.note_count > 1 ? 's':''}
                    </div>
                    {user.id !== userInfo.id && (
                        !followings.some(following => following.id === user.id) ?
                        <Tippy 
                            content={'Send follow request'}
                            placement="left"
                            arrow={true}
                            theme='light-border'
                            delay={[0, 0]}
                        >
                            <div className='follow-icon' onClick={() => requestFollow(user.id)}>
                                <GoPersonAdd size={24} />
                            </div>
                        </Tippy>
                        :
                        <Tippy 
                            content={(<div>You already followed {user.first_name} {user.last_name}</div>)}
                            placement="left"
                            arrow={true}
                            theme='light-border'
                            delay={[0, 0]}
                        >
                            <div className='follow-icon' >
                                <GoPeople size={24} />
                            </div>
                        </Tippy>
                    )}
                </div>
            ))}
        </div>
    )
}    

