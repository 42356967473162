import React, {useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';

const CongratsRegistered = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('justRegistered') !== 'true') {
          navigate('/login');  
        } else {
          setTimeout(() => {
            localStorage.removeItem('justRegistered');
          }, 1000000);
        }
      }, []);

  return (
    <div className='login'>
        <div className='login-form-container'>
            <h1 className='welcome-back thank-you'>Thank you for joining Linko!</h1>
            <p style={{width:'100%', textAlign:'center', fontSize:'16px', marginBottom:'10px'}}>Verification email is sent. <br/>Please check your inbox to activate your account.   </p>
            <div className="login-form-link go-to-login">
              <p>Go to <Link to="/login">Login</Link></p>
            </div>
        </div>
    </div>
  );
};

export default CongratsRegistered;
