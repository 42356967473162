import React, { useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext, FollowContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import Avatar from 'react-avatar';
import NoteCard from '../NoteCard/NoteCard';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { BiLike, BiSolidLike } from "react-icons/bi";
import { putLikeToFeed } from '@linko/shared_utils';

const TabExplore = ({
    explore,
    setExplore,
    isFetchingMoreExplore,
    hasMoreExplore,
    type,
    resourceId,
    }) => { 
    
    const shareView = true; 
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const resourcePage = location.pathname.startsWith('/r/');
    const subjectsPage = location.pathname.startsWith('/s/');
    const homePage = location.pathname.startsWith('/my_linko');
    const {userInfo} = useContext(UserContext);
    const {followings} = useContext(FollowContext);

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        safeNavigate(`/profile/${initial}${profileCode}`);
    };

    explore = explore.map(item => {
        const liked = item.liked_by.some(user => user.id === userInfo.id);
        let ExploreMessage = '';
        if (!item.note) {
            let resourceType;
            switch(item.resource.type) {
                case 'bo':
                    resourceType = 'book';
                    break;
                case 'vi':
                    resourceType = 'video';
                    break;
                case 'ar':
                    resourceType = 'article';
                    break;
                case 'po':
                    resourceType = 'podcast';
                    break;
                case 'oc':
                    resourceType = 'online course';
                    break;
                default:
                    resourceType = 'resource';
            }
            if (resourcePage) {
                if (item.resource.id.toString() === resourceId.toString()) {
                    ExploreMessage = `added this ${type}`;
                } else {
                    ExploreMessage = `added a related ${resourceType}`;
                }
            } else if (subjectsPage) {
                ExploreMessage = `added a related ${resourceType}`;
            } else if (homePage) {
                if (resourceType === 'article' || resourceType === 'online course') {
                    ExploreMessage = `added an ${resourceType}`;
                } else {
                    ExploreMessage = `added a ${resourceType}`;
                }
            }
        } else {
            if (resourcePage) {
                ExploreMessage = `took a note on this ${type}`;
            } else {
                ExploreMessage = "took a note";
            }
        }
        return {
            ...item,
            liked,
            ExploreMessage
        }
    });

    const handleLike = async (id) => {
        const response = await putLikeToFeed(id);
        if (response.status === 200) {
            const updatedExplore = await explore.map(item => {
                const itemId = id.startsWith('N') || id.startsWith('R') ? id.slice(1) : id;
                if (item.id.toString() === itemId) {
                    return {
                        ...item,
                        liked: !item.liked, 
                        liked_by: item.liked 
                        ? item.liked_by.filter(user => user.id !== userInfo.id) 
                        : [...item.liked_by, userInfo]
                    };
                }
                return item; 
            });
            await setExplore(updatedExplore); 
        }
    }
    
    if (followings && followings.length === 0) {
        return (<NoContentPlaceholder activeTab={'explore'}/>);
    }

    if (followings && followings.length > 0 && explore && explore.length === 0 && !isFetchingMoreExplore) {
        return (<NoContentPlaceholder activeTab={'explore'}/>);
    }

    return (
        <div className='explore-tab-content'>
            {explore && 
            <>
            {explore.map((item) => {
                const isNote = item.hasOwnProperty('note');
                    return (
                        <div className="feed-group" key={item.id}>
                            <div className='feed-group-title'>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <Avatar name={item.user.first_name + ' ' + item.user.last_name} size="25" round={true} color="#48c78e" />
                                    <p className='followee-name' onClick={() => goToProfile(item.user.id, item.user.first_name, item.user.last_name)}>
                                        {item.user.first_name} 
                                    </p>
                                    <p className='feed-action-detail'>{item.ExploreMessage}</p>
                                </div>
                                {item.liked ? 
                                    <BiSolidLike className='like-icon' style={{color:'#257953'}} onClick={() => handleLike(isNote ? `N${item.id}` : `R${item.id}`)}/>
                                    :
                                    <BiLike className='like-icon' onClick={() => handleLike(isNote ? `N${item.id}` : `R${item.id}`)}/>
                                }
                            </div>
                            {isNote ? 
                                <div className='cards-container'>
                                    <NoteCard 
                                        key={`N${item.id}`}
                                        note={item} 
                                        shareView={shareView} 
                                    />
                                </div>
                                :
                                <div className='cards-container'>
                                    <ResourceCard 
                                        key={`R${item.id}`}
                                        resource={item} 
                                        shareView={shareView} 
                                        style={{marginRight:'20px'}}
                                    />
                                </div>
                            }
                        </div>
                    );
                })
            }
            </>
            }
            {hasMoreExplore && isFetchingMoreExplore ? 
                <div className='tab-content-loader'>
                    <Loader size={15}/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!isFetchingMoreExplore && explore.length === 0 &&
                    <p className='no-content-placeholder' >No content yet</p>
            }
            {!hasMoreExplore && explore.length >= 10 ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more content</p>
                :
                <div style={{height:'30px'}}></div>
            }
        </div>
    );
};

export default TabExplore;