import React, { useState, useEffect } from 'react';
import { IoSearch, IoClose } from "react-icons/io5";

const SearchInput = ({
    searchTerm,
    setSearchTerm,
    setIsFocused,
    setCloseAllowed,
    isFocused,
    setSearchArea,
    searchArea,
    searchResultsRef
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleFocus = () => {
        setIsFocused(true);
        setCloseAllowed(true);
    };

    const handleBlur = (e) => {
        if (!e.relatedTarget || (searchResultsRef.current && !searchResultsRef.current.contains(e.relatedTarget))) {
            setTimeout(() => setIsFocused(false), 150);
        }
    };

    return windowWidth >= 900 ? (
        <div className='full-search-input' id='navbar-search-large'>
            <IoSearch className="search-icon" />
            <input
                className='full-search-type-area'
                type="text"
                placeholder="Search"
                name="q"
                value={searchTerm}
                autoComplete='off'
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    ) : (
        <div id='navbar-search-small'>
            <IoSearch className="search-icon" onClick={() => setSearchArea(!searchArea)} />
            {searchArea && (
                <div className='full-search-input'>
                    <input
                        className='full-search-type-area'
                        type="text"
                        placeholder="Search"
                        name="q"
                        value={searchTerm}
                        autoComplete='off'
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                        onFocus={() => setIsFocused(true)}
                        onBlur={handleBlur}
                    />
                    <IoClose className="close-icon" onClick={() => setSearchArea(false)} />
                </div>
            )}
            <div className={`search-overlay ${searchArea ? 'visible' : ''}`} onClick={() => setSearchArea(false)}></div>
        </div>
    );
};

export default SearchInput;
