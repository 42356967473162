import React, { useState, useEffect } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import { fetchUserHeatmap } from '@linko/shared_utils';

const Heatmap = () => {
  const [heatmapData, setHeatmapData] = useState([]);
  const date = new Date();

  const fetchHeatmapData = async () => {
    const data = await fetchUserHeatmap();
    setHeatmapData(data);
  };

  useEffect(() => {
    setHeatmapData([]);
    fetchHeatmapData();
  }, []);

  const handleClick = (value) => {
    if (value) {
      alert(`You clicked on ${value.date} with count: ${value.count}`);
    } else {
      alert('No data available');
    }
  };

  const getTooltipDataAttrs = (value) => {
    return {
      'data-tip': value ? `${value.date} has count: ${value.count}` : 'No data available',
    };
  };

  const formatDate = (date) => {
    // Check if date is a valid Date object
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
  
    // Check if the conversion was successful
    if (isNaN(date)) {
      console.error('Invalid date:', date);
      return 'Invalid date';
    }
  
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
    return formattedDate;
  };

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 4);

  return (
      <div className='heatmap' role='heatmap'> 
        <CalendarHeatmap
          data-testid='heatmap'
          startDate={threeMonthsAgo}
          values={heatmapData}
          classForValue={(x) => {
            if (!x) return 'color-empty';
            return x.count > 4 ? 'color-github-4' : `color-github-${x.count}`;
          }}
          tooltipDataAttrs={(value) => getTooltipDataAttrs(value)}
          
          showOutOfRangeDays={true}
          gutterSize={2}
          transformDayElement={(element, value, index) => (
            value ? (
              <Tippy
                key={`tippy-${index}`}
                content={value ? 
                  <div style={{color:'#999999', fontSize:'12px'}}>
                    {value.note > 0 ? 
                    (<> {value.note} note{value.note > 1 ? 's' : ''} </>): ('')}
                    {(value.note !== 0 && value.resource !==0) ? (' and ') : ('')} 
                    {value.resource > 0 ? 
                    (<>{value.resource} resource{value.resource > 1 ? 's' : ''} </>) : ('')}<br />
                    on {formatDate(value.date)}
                  </div>
                  : 
                  null
                }
                placement="bottom"
                arrow={true}
                theme='light-border'
                hideOnClick={true}
                delay={[0, 0]}
              >
                {React.cloneElement(element, { 'data-testid': `heatmap-cell-${index}`, key: `heatmap-cell-${index}` })}
              </Tippy>
            ) : (
              React.cloneElement(element, { 'data-testid': `heatmap-cell-${index}`, key: `heatmap-cell-${index}` })
            )
          )}
        />
      </div>
  );
};

export default Heatmap;