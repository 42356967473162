import React, { useState, useContext, useEffect } from 'react';
import { UserContext, FollowContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import { BiSolidLike } from "react-icons/bi";
import { PiNotePencilBold } from "react-icons/pi";
import ResourceTypeIcon from '../../Components/ResourceCard/ResourceTypeIcon';
import { getNotif, getNotifHistory } from '@linko/shared_utils';
import ReactMarkdown from 'react-markdown';


const Notifications = () => {
    const [newNotifications, setNewNotifications] = useState([]);
    const [notificationsHistory, setNotificationsHistory] = useState([]);
    const navigate = useNavigate();
    const { fetchUserInfo } = useContext(UserContext);
    const { fetchFollowData } = useContext(FollowContext);

    const fetchLikes = async () => {
        const response = await getNotif();
        setNewNotifications(response);
    }

    const fetchNotifHistory = async () => {
        const response = await getNotifHistory();
        setNotificationsHistory(response);
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchNotifHistory();
            await fetchLikes();
            await fetchUserInfo();  
            await fetchFollowData();
        };
        fetchData();
    }, []);

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        navigate(`/profile/${initial}${profileCode}`);
    };

    return (
        <div className='notifications'>
         
            <h2 className='follow-title'>Notifications</h2>
            <h3 style={{fontSize:'18px', fontWeight: '600', margin:'15px 0'}}>New</h3>
            {(newNotifications && newNotifications.length === 0) ?
                <h2 style={{fontStyle:'italic', fontSize:'16px', color:'#8f8f93'}} className='notification-group'>No content</h2> 
                : 
                <>
                {newNotifications.map((notification) => {
                    const isNote = notification.hasOwnProperty('note');
                    return (
                        <div className='notification-group notification-with-card' key={notification.id}>
                            <div className='like-icon'>
                                <BiSolidLike size={20} />
                            </div>
                            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                                <p style={{marginBottom:'10px'}}>
                                    <span className='name-span' onClick={goToProfile.bind(this, notification.user.id, notification.user.first_name, notification.user.last_name)}>
                                        {notification.user.first_name} {notification.user.last_name}
                                    </span> 
                                    {isNote ? ' liked your note' : ' liked a resource you added'}
                                </p>

                                {isNote ? 
                                    <div className='content-card' style={{cursor:'pointer'}} onClick={() => navigate(`/n/${btoa(notification.note.id)}`)}>
                                        <div className='icon'>
                                            <i className='resource-type-icon'>
                                                <PiNotePencilBold size={24} className='type'/>
                                            </i>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            {notification.note.title && <p className='title'>{notification.note.title}</p>}
                                            <div className='content-preview'>
                                                <ReactMarkdown>{notification.note.note}</ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='content-card liked-resource' style={{cursor:'pointer'}} onClick={() => navigate(`/r/${notification.resource.id}`)}>
                                        <ResourceTypeIcon type={notification.resource.type} className='type'/>
                                        <p className='title'>{notification.resource.title}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })}
                </>
            }
        <div style={{height:'1px', width:'100%', backgroundColor:'#e0e0e0', margin:'10px 0'}}></div>
        <h3 style={{fontSize:'18px', fontWeight: '600', margin:'10px 0 30px 0'}}>Past 30 days</h3>
            {(notificationsHistory && notificationsHistory.length === 0) ?
                    <h2 style={{fontStyle:'italic', fontSize:'16px', color:'#8f8f93'}} className='notification-group'>No content</h2> 
                    : 
                    <>
                    {notificationsHistory.map((notification) => {
                        const isNote = notification.hasOwnProperty('note');
                        return (
                            <div className='notification-group notification-with-card' key={notification.id}>
                                <div className='like-icon'>
                                    <BiSolidLike size={20} />
                                </div>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                                    <p style={{marginBottom:'10px'}}>
                                        <span className='name-span' onClick={goToProfile.bind(this, notification.user.id, notification.user.first_name, notification.user.last_name)}>
                                            {notification.user.first_name} {notification.user.last_name}
                                        </span> 
                                        {isNote ? ' liked your note' : ' liked a resource you added'}
                                    </p>

                                    {isNote ? 
                                        <div className='content-card' style={{cursor:'pointer'}} onClick={() => navigate(`/n/${btoa(notification.note.id)}`)}>
                                            <div className='icon'>
                                                <i className='resource-type-icon'>
                                                    <PiNotePencilBold size={24} className='type'/>
                                                </i>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                {notification.note.title && <p className='title'>{notification.note.title}</p>}
                                                <div className='content-preview'>
                                                    <ReactMarkdown>{notification.note.note}</ReactMarkdown>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='content-card liked-resource' style={{cursor:'pointer'}} onClick={() => navigate(`/r/${notification.resource.id}`)}>
                                            <ResourceTypeIcon type={notification.resource.type} className='type'/>
                                            <p className='title'>{notification.resource.title}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })}
                    </>
                }
        </div>
    );
};

export default Notifications;