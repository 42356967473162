import AsyncStorage from '@react-native-async-storage/async-storage';

const storageUtils = (() => {

if (typeof window !== 'undefined' && window.localStorage) {
  return {
    getItem: (key) => localStorage.getItem(key),
    setItem: (key, value) => localStorage.setItem(key, value),
    removeItem: (key) => localStorage.removeItem(key),
    clear: () => localStorage.clear()
  };
} else {
  try {
    return {
        getItem: (key) => AsyncStorage.getItem(key),
        setItem: (key, value) => AsyncStorage.setItem(key, value),
        removeItem: (key) => AsyncStorage.removeItem(key),
        clear: () => AsyncStorage.clear()
    };
  } catch (error) {
    console.error('AsyncStorage not available', error);
    return {
        getItem: () => Promise.reject('AsyncStorage not available'),
        setItem: () => Promise.reject('AsyncStorage not available'),
        removeItem: () => Promise.reject('AsyncStorage not available'),
        clear: () => Promise.reject('AsyncStorage not available')
    };
  }
}

})();

export default storageUtils;
