import React, {useContext} from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { IoLibraryOutline } from "react-icons/io5";

const LibraryButton = () => {
    const { safeNavigate } = useContext(NavigationPromptContext);

    const handleLibraryClick = () => {
        safeNavigate('/my_library');
    }
    
    return (
        <button className='linko-button linko-button--tertiary library-button'  onClick={handleLibraryClick}>
            <IoLibraryOutline color="#48c78e"/> 
            <span className='button-text'>My Library</span>
        </button>
    );
};

export default LibraryButton;