import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext, AuthContext, FocusContext, LoadingContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import AddToLinko from './AddToLinko';
import LibraryButton from './LibraryButton';
import Search from './Search';
import NameDropdown from './NameDropdown';
import ScrollToTopLink from '../ScrollToTopLink';
import { FollowContext } from '../../Context/AppContext';
import FocusModeNav from './FocusModeNav';

function NavBar() {
  const { userInfo } = useContext(UserContext);
  const { checkAuthStatus, isAuthenticated } = useContext(AuthContext);
  const { focus } = useContext(FocusContext);
  const { isLoading } = useContext(LoadingContext);
  const [currentPage, setCurrentPage] = useState('');
  const { notifications } = useContext(FollowContext);
  const location = useLocation();
  const { safeNavigate } = useContext(NavigationPromptContext);

  // Check authentication status on mount
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Set Path
  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setCurrentPage(path);
  }, [location]);

  // Handle Scroll
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar-container');
      const path = location.pathname;
      const noShadowPaths = ['/my_linko', '/r/', '/s/', '/profile/'];
      const shouldApplyShadow = !noShadowPaths.some(noShadowPath => path.startsWith(noShadowPath));
      if (window.scrollY > 0 && shouldApplyShadow) {
        navbar?.classList?.add('navbar-shadow');
      } else {
        navbar?.classList?.remove('navbar-shadow');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const navbar = document.querySelector('#navbar-container');
      const currentScroll = window.scrollY || document.documentElement.scrollTop;
      if (navbar) {
        if (currentScroll > lastScrollTop) {
          // Scrolling down
          navbar.style.top = '-70px'; // Adjust based on navbar height
        } else {
          // Scrolling up
          navbar.style.top = '0';
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile Safari
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove the event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (focus) {
    return <FocusModeNav />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div id="navbar-container" style={location.pathname === '/' ? {backgroundColor: '#effaf5'} : {backgroundColor: '#fff'}}>
      <nav className="linko-navbar" role="navigation" aria-label="main navigation">
        {/* Large */}
        <div className="linko-navbar-brand" id='navbar-brand-large'>
          <p className='logo' onClick={() => safeNavigate('/my_linko')}>
            Linko
          </p>
          <p id="beta">
            Beta
          </p>
          <p className="slogan">
            Learning as a lifestyle
          </p>
        </div>
        {isAuthenticated && userInfo ? (
          <>
          <div id='navbar-menu-large'>
            <div className="navbar-end-menu">
              <div className="linko-navbar-end">
                <Search />
                <AddToLinko /> 
                <LibraryButton />
                <NameDropdown userInfo={userInfo} notifications={notifications}/>
              </div>
            </div>
          </div>
          </>
        ) : currentPage === '' && (
          <div className='landing-page-actions'>
            <ScrollToTopLink to='/login' className='login-button'>Log in</ScrollToTopLink>
          </div>
        )}
      </nav>
    </div>
  );
}

export default NavBar;
