import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { TbArrowBackUp } from "react-icons/tb";

const BookManualInput = ({onClose, setOpenManualInput}) => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [description, setDescription] = useState('');
    const [isbn, setIsbn] = useState('');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleAuthorChange = (e) => {
        setAuthor(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your logic here to handle the form submission
    };

    return (
        <div className="add-modal" >
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div onClick={() => setOpenManualInput(false)} style={{display:'flex', alignItems:'center'}}>
                    <TbArrowBackUp className='TbArrowBackUp' size={20}/> 
                    <p>Back to search</p>
                </div>
                <IoClose onClick={onClose} className="close-button"/>
            </div>
            <form onSubmit={handleSubmit}>
                <label >
                    Title:
                    <input type="text" value={title} onChange={handleTitleChange} required/>
                </label>
                <br />
                <label>
                    Author:
                    <input type="text" value={author} onChange={handleAuthorChange} />
                </label>
                <br />
                <label>
                    ISBN:
                    <input type="text" value={isbn} onChange={(e) => setIsbn(e.target.value)} />
                </label>
                <label>
                    Description:
                    <textarea value={description} onChange={handleDescriptionChange} />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default BookManualInput;