import React from 'react';

const ResourceDescription = ({description}) => {
    if (!description || description === "") {
        return (
            <div className="resource-description">
                <p style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center'}}>No description available</p>
            </div>
        );
    }
    return (
        <div
            className="resource-description"
            style={{overflowY: 'auto'}}
            dangerouslySetInnerHTML={{ __html: description }}
        />
    );
};

export default ResourceDescription;