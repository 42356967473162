import React, {useState, useEffect, useContext} from 'react';
import { useLocation }  from 'react-router-dom';
import ResourceTypeIcon from '../../Components/ResourceCard/ResourceTypeIcon';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { fetchResources, fetchBestUnlearned, postResourceToMine } from '@linko/shared_utils';
import { MdOutlineLibraryAdd } from "react-icons/md";
import { useToast } from '../../Components/ToastNotification';
import Tippy from '@tippyjs/react';

const RecentlyAdded = ({subjectId}) => {

    const [resources, setResources] = useState([]);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { setPersistentMessage } = useToast(); 
    const location = useLocation();
    const homePage = location.pathname === '/my_linko';
    const subjectPage = location.pathname.startsWith('/s/');
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const signal = null;

    const fetchRecentlyAdded = async () => {
        await fetchResources( 5, 0, [], [], [], resources, setResources, setResourcesOffset, setHasMoreResources, setIsFetchingMoreResources, signal);
    };

    const fetchSubjectTopResources = async () => {
        if (subjectId) {
           const response = await fetchBestUnlearned(subjectId);
            if (response.detail === "No unlearned resources found for the given subject." ) {
                setResources([])
            } else {
                setResources(response.data);   
            }
        } 
    }

    useEffect(() => {
        setResources([]);
        if (homePage) {
            fetchRecentlyAdded();
        } else if (subjectPage && subjectId) {
            fetchSubjectTopResources();
        }
    }, [location, subjectId]); 

    const addResourceToMine = async (resourceId) => {
        await postResourceToMine(resourceId);
        setPersistentMessage('Resource added to your library!');
    }

    
    if (!resources || resources.length === 0) {
        return null;
    }

    return (
        <div className='recently-added'>
            <div style={{display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
                <div className='subject-rel-title'>
                    {homePage ? 'Recently Added' : subjectPage ? 'Top Resources' : ''}
                </div>
            </div>
            <div className='recently-added-resources mt-10'>
                {resources && resources.map((resource) => {
                    const resourceData = resource.resource ? resource.resource : resource;
                    return (
                    <div className='recently-added-resource' onClick={() => safeNavigate(`/r/${resourceData.id}`)} key={resourceData.id}>
                        <ResourceTypeIcon type={resourceData.type}/>
                        <div style={{flexGrow:1}}>
                            <div className='resource-title' style={{width: subjectPage ? '300px' : '340px'}}>{resourceData.title}</div>
                            <div className='resource-author' style={{width: subjectPage ? '300px' : '340px'}}>{resourceData.author}</div>
                        </div>
                        {subjectPage && 
                            <Tippy 
                                content={'Add to my library'}
                                placement="left"
                                arrow={true}
                                theme='light-border'
                            >
                                <div className='add-resource-icon' onClick={() => addResourceToMine(resourceData.id)}>
                                    <MdOutlineLibraryAdd size={22} />
                                </div>
                            </Tippy>
                        }
                    </div>
                )})}
            </div>
        </div>
    );
};

export default RecentlyAdded;