import React, {useState, useEffect, useContext} from 'react';
import { FollowContext, UserContext } from '../../Context/AppContext';
import { GoPersonAdd, GoPeople } from "react-icons/go";
import { sendFollowRequest } from '@linko/shared_utils';

const PublicProfileHeader = ({
    userId,
    userFirstName,
    userLastNameInitial,
}) => {
    const {requesting, followers, followings} = useContext(FollowContext);
    const {userInfo} = useContext(UserContext);
    const myPage = userInfo && userInfo.id === userId;
    const [friendStatus, setFriendStatus] = useState(null);
    const [notFollowing, setNotFollowing] = useState(true);
    const [comparingDone, setComparingDone] = useState(false);



    useEffect(() => {
        try {
            if (userFirstName !== null) {
                if (followers.some(follower => follower.id === userId) && !followings.some(following => following.id === userId)) {
                    setFriendStatus(`${userFirstName} is following you`);
                    setNotFollowing(true);
                } else if (followings.some(following => following.id === userId) && !followers.some(follower => follower.id === userId)) {
                    setFriendStatus(`You are following ${userFirstName}`);
                    setNotFollowing(false);
                } else if (followers.some(follower => follower.id === userId) && followings.some(following => following.id === userId)) {
                    setFriendStatus(`You and ${userFirstName} are following each other`);
                    setNotFollowing(false);
                } else if (requesting.some(request => request.id === userId)) {
                    setFriendStatus(`Follow request pending`);
                    setNotFollowing(false);
                } else {
                    setFriendStatus(null);
                    setNotFollowing(true); 
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setComparingDone(true);
        }
    }, [requesting, userId, userFirstName, followers, followings]);

    const handleAddFriend = async () => {
        const response = await sendFollowRequest(userId);
        if (response.status === 200 ) {
            setFriendStatus(`Follow request pending`);
            setNotFollowing(false);
        } else {
            console.error('Failed to send friend request');
        }
    }

    return (
        <div className='profile-left-header'>
            {userFirstName && comparingDone &&
                <>
                    <div className='header-group'>
                    <h1 className='subject-name'>{userFirstName} {userLastNameInitial}</h1>
                    {notFollowing ? 
                        <button className='add-friend-icon linko-button linko-button--tertiary' title='Request follow' onClick={handleAddFriend}>
                            <GoPersonAdd />
                            <span>Request follow</span>
                        </button>
                        :
                        <button className='add-friend-icon linko-button linko-button--tertiary' title='View friends' disabled style={{cursor:'auto'}}>
                            <GoPeople />
                        </button>
                    }
                    </div>
                    {!myPage && 
                        <>
                            {friendStatus !== null && <p className='friend-status'>{friendStatus}</p>}
                        </>
                    }
                </>
            }
        </div>
    );
};

export default PublicProfileHeader;