import React from 'react';
import { useNavigation } from '../Context/NavigationContext';
import { FaArrowLeft } from "react-icons/fa6";


const BackButton = () => {
  const { goBack } = useNavigation();

  return (
    <div className='back-button-wrapper'>
      <button onClick={goBack} className='linko-button linko-button--cancel' style={{height: '40px', width:'40px', padding:'0'}}>
        <FaArrowLeft size={24}/>
      </button>
    </div>  
  );
};

export default BackButton;