import React, {useState, useEffect, useRef, useContext} from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useLocation } from 'react-router-dom';
import { UserSubjectContext } from '../../Context/AppContext';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import { searchKnowledge, searchMyResources, searchMyNotes, searchUsers } from '@linko/shared_utils';

const Search = () => {
    const {userSubject} = useContext(UserSubjectContext); 
    const { safeNavigate } = useContext(NavigationPromptContext);
    const [users, setUsers] = useState([]);
    const [resources, setResources] = useState([]);
    const [notes, setNotes] = useState([]);
    const [subject, setSubject] = useState({ studied: [], notStudied: [] }); 
    const [isFocused, setIsFocused] = useState(true); 
    const [searchArea, setSearchArea] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [isLoading, setIsLoading] = useState(false);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const timeoutIdOne = useRef(); 
    const timeoutIdTwo = useRef(); 
    const timeoutIdThree = useRef();
    const timeOutIdFour = useRef();
    const searchResultsRef = useRef(null); 
    const sectionTitleRefs = useRef([]);
    sectionTitleRefs.current = [useRef(null), useRef(null), useRef(null)];
    const location = useLocation();
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        entry.target.classList.add('sticky');
                    } else {
                        entry.target.classList.remove('sticky');
                    }
                });
            },
            { 
                threshold: 0.1 
            } 
        );

        sectionTitleRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up
        return () => {
            sectionTitleRefs.current.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    useEffect(() => {
        const fetchSubject = async () => {
            if (searchTerm.length >= 2) {
                setIsLoading(true);
                timeoutIdOne.current && clearTimeout(timeoutIdOne.current);
                timeoutIdOne.current = setTimeout(async () => {
    
                    const data = await searchKnowledge(searchTerm);
                    const studiedResults = data.filter(subject =>
                        userSubject.some(studied => studied.id === subject.id)
                    );

                    const notStudiedResults = data.filter(subject =>
                        !userSubject.some(studied => studied.id === subject.id)
                    );

                    setSubject({studied: studiedResults, notStudied: notStudiedResults});
                    setIsLoading(false);
                }, 1000);
            } else {
                setSubject({studied: [], notStudied: []});
            }
        };
        fetchSubject();
    }, [searchTerm]);

    useEffect(() => {
        const fetchResources = async () => {
            if (searchTerm.length >= 2) {
                setIsLoading(true);
                timeoutIdTwo.current && clearTimeout(timeoutIdTwo.current);
                timeoutIdTwo.current = setTimeout(async () => {
                    const data = await searchMyResources(searchTerm);
                    setResources(data);
                    setIsLoading(false);
                }, 1000);
            } else {
                setResources([]);
            }
        };
        fetchResources();
    }, [searchTerm]);

    useEffect(() => {
        const fetchUsers = async () => {
            if (searchTerm.length >= 2) {
                setIsLoading(true);
                timeoutIdThree.current && clearTimeout(timeoutIdThree.current);
                timeoutIdThree.current = setTimeout(async () => {
                    const data = await searchUsers(searchTerm);
                    setUsers(data);
                    setIsLoading(false);
                }, 1000);
            } else {
                setUsers([]);
            }
        };
        fetchUsers();
    }, [searchTerm]);

    useEffect(() => {
        const fetchNotes = async () => {
            if (searchTerm.length >= 2) {
                setIsLoading(true);
                timeOutIdFour.current && clearTimeout(timeOutIdFour.current);
                timeOutIdFour.current = setTimeout(async () => {
                    const data = await searchMyNotes(searchTerm);
                    setNotes(data);
                    setIsLoading(false);
                }, 1000);
            } else {
                setNotes([]);
            }
        };
        fetchNotes();
    }, [searchTerm]);

    useEffect(() => {
        setSearchTerm('');
    }, [location]);

    useEffect(() => {
        setSearchArea(false);
    }
    , [location]);


    return (
        <div id="search" >
            <form method="get" action="/search" id='search-form'>
                <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setIsFocused={setIsFocused}
                    setCloseAllowed={setCloseAllowed}
                    isFocused={isFocused}
                    setSearchArea={setSearchArea}
                    searchArea={searchArea}
                    searchResultsRef={searchResultsRef}
                />
                {isFocused && searchTerm !== '' && (
                    <SearchResults
                        isLoading={isLoading}
                        users={users}
                        resources={resources}
                        notes={notes}
                        subject={subject}
                        isFocused={isFocused}
                        searchTerm={searchTerm}
                        setCloseAllowed={setCloseAllowed}
                    />
                )}
            </form>
        </div>
    );
};

export default Search;
