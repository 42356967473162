import React from 'react';
import ScrollToTopLink from '../../Components/ScrollToTopLink';
import SampleSubjectPage from '../../static/images/sample-subject-page.png'
import SampleNote  from '../../static/images/sample-note.png'
import SampleResource from '../../static/images/sample-resource.png'

const Landing = () => {

  return ( 
    <div className='landing-page'>
      <div className='hero'>
        <div className='hero-text'>
          <h1>For every curious mind</h1>
          <p>
          Linko is the single platform you need to capture, reflect, and explore knowledge. Join the community where ideas connect and learning thrives.
          </p>
          <ScrollToTopLink to='/register' className='sign-up'>Sign up for early access</ScrollToTopLink>
        </div>
        <div className='hero-img'>
          <img src={SampleSubjectPage} alt='Sample Subject Page' className='hero-img-subject'/>
          <img src={SampleNote} alt='Sample Note' className='hero-img-note'/>
          {/* <img src={SampleResource} alt='Sample Resource' className='hero-img-resource'/> */}
        </div>
      </div>
    </div>
  );
};

export default Landing;
