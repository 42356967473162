import React, {useContext} from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useModal } from '../../Context/ModalContext';


const LearnedUsers = ({learnedUserData}) => {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const { requestInfo, requestConfirm, requestAlert } = useModal();

    const friends = learnedUserData?.following_user_learned;
    const publicUsers = learnedUserData?.public_user_learned;
    const privateUsers = learnedUserData?.private_user_learned_count;
    const nonFriendsCount = publicUsers?.length + privateUsers;
    const totalCount = friends?.length + nonFriendsCount;

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        safeNavigate(`/profile/${initial}${profileCode}`);
    };

    const seeLearnedUsers = () => {
        requestInfo({
            data: publicUsers, 
            count: privateUsers,
            type: 'learnedUsers',
        });
    };

    if (totalCount === 0) {
        return null;
    }
    return (
        <div className='learned-users'>
            {friends?.length > 0 && (
                <>
                    {friends.map((friend, index) => (
                        <span 
                        key={friend.id} 
                        onClick={() => goToProfile(friend.id, friend.first_name, friend.last_name)}
                        className='hover-to-primary-color'
                        >
                            {friend.first_name} {friend.last_name}
                            {index !== friends.length - 1 && ', '}
                        </span>
                    ))}
                </>
            )}
            {nonFriendsCount > 0 && (
                <span className='hover-add-underline' onClick={seeLearnedUsers}>
                    {friends?.length > 0 && ' and '}
                    {nonFriendsCount}
                    {friends?.length > 0 && ' other'}
                    {nonFriendsCount >= 2 ? ' users ' : ' user '}
                </span>
            )}
            {totalCount >= 2 ? 'have learned this' : 'has learned this'}
        </div>
    );
};

export default LearnedUsers;