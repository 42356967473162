import React from 'react';

const SubjectDescription = ({ description, noteCount, resourceCount }) => {

    return (
        <div className='subject-description'>
            <div style={{marginBottom:'10px'}}>
                { noteCount > 0 && <span className='subject-learn-count'>{noteCount} note{noteCount > 1 ? 's':''}</span>}
                { resourceCount > 0 && <span className='subject-learn-count'>{resourceCount} resource{resourceCount > 1 ? 's':''}</span>}
            </div>
            <div className='description'>{description}</div>
        </div>
    );
};

export default SubjectDescription;
