import React, { useEffect, useState, useContext } from 'react';
import { UserSubjectContext } from '../../Context/AppContext'
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';

const YourSubjects = () => {

    const { userSubject } = useContext(UserSubjectContext);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const myTags = userSubject.filter(item => item.is_linked === false);
    const topSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.learn_count - a.learn_count).slice(0, 15);

    return (
        <div className='your-subjects'>
            <div className='your-subjects-section hide-scrollbar'>
                {topSubject.map((field) => (
                    <p key={field.id} className='subject-tag studied' onClick={() => safeNavigate(`/s/${encodeURIComponent(field.name)}`)}>
                        {field.name}
                    </p>
                ))}
            </div>
            <div className='your-subjects-section hide-scrollbar'>
                {myTags && myTags.map((tag) => (
                    <p key={tag.id} className='cus-label' onClick={() => safeNavigate(`/s/${encodeURIComponent(tag.name)}`)}>
                        {tag.name}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default YourSubjects;