import React, {useState, useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigation } from '../../Context/NavigationContext';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import BackButton from '../../Components/BackButton';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import SubjectPageHeader from './SubjectPageHeader';
import SubjectDescription from './SubjectDescription';
import SubjectRel from './SubjectRel';
import LeaderBoard from './LeaderBoard';
import RecentlyAdded from '../HomePage/RecentlyAdded';
import HalfFooter from '../../Components/NavAndFooter/HalfFooter';
import { fetchSubjectId } from '@linko/shared_utils';

const SubjectPage = () => {

  const {subjectName} = useParams();
  const location = useLocation();
  const { pushPage } = useNavigation();
  const [subjectId, setSubjectId] = useState(null);
  const [description, setDescription] = useState('');
  const [broaderField, setBroaderField] = useState([]);
  const [siblingFields, setSiblingFields] = useState([]);
  const [childFields, setChildFields] = useState([]);
  const [publicField, setPublicField] = useState(null);
  const [noteCount, setNoteCount] = useState(null);
  const [resourceCount, setResourceCount] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [notes, setNotes] = useState([]);
  const [library, setLibrary] = useState([]);
  const [editTag, setEditTag] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  useEffect(() => {
    const fetchSubjectInfo = async () => {
      try {
        const subjectInfo = await fetchSubjectId(subjectName);
        setSubjectId(subjectInfo.subjectId);
        setDescription(subjectInfo.description);
        setPublicField(subjectInfo.publicField);
        setBroaderField(subjectInfo.broaderField);
        setSiblingFields(subjectInfo.siblingFields);
        setChildFields(subjectInfo.childFields);
        setNoteCount(subjectInfo.noteCount);
        setResourceCount(subjectInfo.resourceCount);
        setTopUsers(subjectInfo.topUsers);
      } catch (error) {
        console.error('Error fetching subject info:', error);
      }
    };

    setLibrary([]);
    fetchSubjectInfo();
  }, [subjectName]);

  useEffect(() => {
    pushPage(location.pathname);
  }, [location.pathname]);

  return (
    <div className='page-container subject-page'>
      <div className='page-right'>
        <BackButton />
        <SubjectPageHeader
          editTag={editTag}
          setEditTag={setEditTag}
          subjectName={subjectName}
          publicField={publicField}
          subjectId={subjectId}
          description={description}
          setDescription={setDescription}
        />
        {!editTag && 
        <>
          <SubjectDescription description={description} noteCount={noteCount} resourceCount={resourceCount}/>
          <AddNoteButton 
            subjectId={subjectId} 
            noteHint={'Take a note under this topic'} 
            showTextArea={showTextArea} 
            setShowTextArea={setShowTextArea} 
            setNotes={setNotes} 
            setLibrary={setLibrary} 
          />
        </>}     
        <TheTabs 
          notes={notes}
          setNotes={setNotes}
          library={library}
          setLibrary={setLibrary}
          subjectId={subjectId} 
          publicField={publicField}
        />
      </div>
      <div className='home-page-right'>
      {publicField && 
      <>
        <SubjectRel 
          broaderField={broaderField}
          siblingFields={siblingFields} 
          childFields={childFields} 
        />
        <RecentlyAdded subjectId={subjectId} />
        <LeaderBoard topUsers={topUsers} />
        <HalfFooter />
      </>  
      }  
      </div>
    </div>
  );
};

export default SubjectPage;
