
import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";

const LoaderComponent = () => {
    return (
        <div className='loader-container'>
            <BounceLoader color={"#6DD6A7"} className='the-loader'/>
        </div>
    );
}

export default LoaderComponent;
