import React from 'react';
import { useLocation } from 'react-router-dom';
import LibraryTabs from './LibraryTabs';
import { IoLibraryOutline } from "react-icons/io5";


function MyLibrary() {

  const location = useLocation();
  const initialTab = location.state?.activeTab || 'notes';

  return (
    <div className='my-library'>
      <div className='my-library-header'>
        <IoLibraryOutline size={30} />
        <p>My Library</p>
      </div>
      <LibraryTabs initialTab={initialTab}/>
    </div>
  );
};

export default MyLibrary; 
