import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>
            <h1>Privacy Policy for Linko</h1>
            <p>
                <span>Last updated: 03/13/2024</span> 
            </p>
            <p>
                Welcome to Linko! We are committed to protecting your personal information and your right to privacy. 
                If you have any questions or concerns about this privacy policy or our practices with regard to your personal information, 
                please contact us at <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>.
            </p>
            <p>
                This privacy policy describes how we might use, manage, 
                and protect your information when you use our website and services (collectively, "Services").  
            </p>
            <h2>Information We Collect</h2>
            <p>
                We collect personal information that you voluntarily provide to us when registering for the Services, 
                expressing an interest in obtaining information about us or our products and services, 
                or otherwise contacting us. The personal information we collect depends on the context of your interactions with us and the Services, 
                the choices you make, and the products and features you use.
            </p>
            <h3>Types of Information Collected:</h3>
                <ul>
                    <li><span>Personal Information:</span> This includes names, email addresses, usernames, and any other information that you provide to us.</li>
                    <li><span>Usage Data:</span> Information on how the Services are accessed and used, including your interaction with the Services.</li>
                    <li><span>Technical Data:</span> Includes IP addresses, browser type, operating system, and other technology on the devices you use to access the Services.</li>
                    <li><span>Payment Data:</span> We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument.</li>
                </ul>
            <h2>How We Use Your Information</h2>
            <p>
                We use personal information collected via our Services for a variety of business purposes, including:
            </p>
                <ul>
                    <li>To provide, operate, and maintain our Services</li>
                    <li>To improve, personalize, and expand our Services</li>
                    <li>To understand and analyze how you use our Services</li>
                    <li>To develop new products, services, features, and functionality</li>
                    <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>To send you emails</li>
                    <li>To find and prevent fraud</li>
                </ul>
            <h2>Sharing Your Information</h2>
            <p>
                We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.
            </p>
            <ul>
                <li><span>Third-Party Service Providers:</span> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                <li><span>Business Partners:</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                <li><span>Affiliates:</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy.</li>
                <li><span>Other Users:</span> When you share personal information or otherwise interact with public areas of the Services, such information may be viewed by all users and may be publicly distributed outside the Services in perpetuity.</li>
            </ul>
            <h2>Retention of Your Information</h2>
            <p>
                We will retain your personal information only for as long as is necessary for the purposes set out in this privacy policy, 
                and to the extent necessary to comply with our legal obligations, resolve disputes, 
                and enforce our legal agreements and policies.
            </p>
            <h2>Security of Your Information</h2>
            <p>
                We take reasonable measures to protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. 
                We cannot guarantee the security of any information that is transmitted to or through the Services or that is stored on our systems.
            </p>
            <h2>Your Privacy Rights</h2>
            <p>
                Depending on your location, you may have rights under applicable data protection laws to request access to, 
                correct, or delete the personal information we have about you.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
                We reserve the right to make changes to this privacy policy at any time. 
                If we make changes, we will post the updated policy and update the effective date at the top of this page.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have questions or comments about this privacy policy, please contact us at: <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>
            </p>
        </div>
    );
};

export default PrivacyPolicy;