import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import TabNotes from '../../Components/ContentTabs/TabNotes';
import TabResources from '../../Components/ContentTabs/TabResources';
import LibraryFilter from './LibraryFilter';
import LibrarySubjectFilter from './LibrarySubjectFilter';
import { handleScrollEvent, setupScrollListeners } from '../../Components/ContentTabs/handleScroll';
import { fetchNotes, fetchResources } from '@linko/shared_utils';

const LibraryTabs = () => {

    const resourceAbortControllerRef = useRef(null); // Initialize the ref to null
    const noteAbortControllerRef = useRef(null);
    const isNoteInitialMount = useRef(true);
    const isResourceInitialMount = useRef(true);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'resources');

    const [notes, setNotes] = useState([]);
    const [notesOffset, setNotesOffset] = useState(0);
    const [isFetchingMoreNotes, setIsFetchingMoreNotes] = useState(true);
    const [hasMoreNotes, setHasMoreNotes] = useState(true);
    const [noteFilterSubject, setNoteFilterSubject] = useState([]);

    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(true);
    const [hasMoreResources, setHasMoreResources] = useState(true);

    const [filterType, setFilterType] = useState([]);
    const [filterFinished, setFilterFinished] = useState(null);
    const [resourceFilterSubject, setResourceFilterSubject] = useState([]);

    const [isFetchingMoreContentFeed, setIsFetchingMoreContentFeed] = useState(false);
    const [hasMoreContentFeed, setHasMoreContentFeed] = useState(true);

    const limit = 20;
    const latestResourceRequest = useRef(0);
    const latestNoteRequest = useRef(0);

    const applyResourceFilter = async () => {
        const currentRequest = Date.now();
        latestResourceRequest.current = currentRequest;

        if (resourceAbortControllerRef.current) {
            resourceAbortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        resourceAbortControllerRef.current = abortController;

        await setResources([]);
        await setResourcesOffset(0);
        await setHasMoreResources(true);
        await setIsFetchingMoreResources(true);

        await fetchResources(
            limit,
            0,
            filterType,
            filterFinished,
            resourceFilterSubject,
            resources,
            setResources,
            setResourcesOffset,
            setHasMoreResources,
            setIsFetchingMoreResources,
            resourceAbortControllerRef.current.signal
        );
    };

    const applyNoteFilter = async () => {
        const currentRequest = Date.now();
        latestNoteRequest.current = currentRequest;

        if (noteAbortControllerRef.current) {
            noteAbortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        noteAbortControllerRef.current = abortController;

        await setNotes([]);
        await setNotesOffset(0);
        await setHasMoreNotes(true);
        await setIsFetchingMoreNotes(true);
        
        await fetchNotes(
            limit,
            notesOffset,
            null,
            null,
            notes,
            setNotes,
            setNotesOffset,
            setHasMoreNotes,
            setIsFetchingMoreNotes,
            noteFilterSubject,
            noteAbortControllerRef.current.signal
        );
    };

    useEffect(() => {
        if (isNoteInitialMount.current) {
            isNoteInitialMount.current = false;
        } else {
            applyNoteFilter();
        }
    }, [noteFilterSubject]);

    useEffect(() => {
        if (isResourceInitialMount.current) {
            isResourceInitialMount.current = false;
        } else {
            applyResourceFilter();
        }
    }, [resourceFilterSubject, filterType, filterFinished]);

    useEffect(() => {
        if (isFetchingMoreNotes) {
            if (!noteAbortControllerRef.current) {
                noteAbortControllerRef.current = new AbortController();
            }
            fetchNotes(limit, notesOffset, null, null, notes, setNotes, setNotesOffset, setHasMoreNotes, setIsFetchingMoreNotes, noteFilterSubject, noteAbortControllerRef.current.signal);
        }
    }, [isFetchingMoreNotes]);

        useEffect(() => {
            if (isFetchingMoreResources) {
                if (!resourceAbortControllerRef.current) {
                    resourceAbortControllerRef.current = new AbortController();
                }
                fetchResources(limit, resourcesOffset, filterType, filterFinished, resourceFilterSubject, resources, setResources, setResourcesOffset, setHasMoreResources, setIsFetchingMoreResources, resourceAbortControllerRef.current.signal);
            }
        }, [isFetchingMoreResources]);

        useEffect(() => {
            const cleanupScrollListeners = setupScrollListeners(handleScrollEvent, activeTab, setIsFetchingMoreNotes, setIsFetchingMoreResources, setIsFetchingMoreContentFeed, hasMoreNotes, hasMoreResources, hasMoreContentFeed);

            return () => {
                cleanupScrollListeners();
            };
        }, [activeTab, hasMoreNotes, hasMoreResources, hasMoreContentFeed]);

        const handleTabChange = (tab) => {
            setActiveTab(tab);
        };

        useEffect(() => {
            let lastScrollTop = 0;
            const handleScroll = () => {
                const navbar = document.querySelector('.library-tabs-wrapper');
                const currentScroll = window.scrollY || document.documentElement.scrollTop;
                if (navbar) {
                    if (currentScroll > lastScrollTop) {
                        // Scrolling down
                        navbar.style.top = '0px';
                    } else {
                        // Scrolling up
                        navbar.style.top = '70px';
                    }
                    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);


        return (
            <div className='library-tabs'>
                <div className='library-tabs-wrapper' style={{ marginBottom: activeTab === 'notes' ? '10px' : '0px' }}>
                    <div className='tabs'>
                        <button
                            onClick={() => handleTabChange('resources')}
                            className={'tab-button' + (activeTab === 'resources' ? ' active' : '')}
                        >
                            Resources
                        </button>
                        <button
                            onClick={() => handleTabChange('notes')}
                            className={'tab-button' + (activeTab === 'notes' ? ' active' : '')}
                        >
                            Notes
                        </button>
                        
                    </div>
                </div>
                <div className='tab-content'>
                    {activeTab === 'notes' &&
                        <>
                            <LibrarySubjectFilter
                                filterSubject={noteFilterSubject}
                                setFilterSubject={setNoteFilterSubject}
                                activeTab={activeTab}
                            />
                            <TabNotes
                                notes={notes}
                                setNotes={setNotes}
                                isFetchingMoreNotes={isFetchingMoreNotes}
                                hasMoreNotes={hasMoreNotes}
                            />
                        </>
                    }
                    {activeTab === 'resources' &&
                        <>
                            <div className='filter-button-wrapper' >
                                <LibraryFilter
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                    filterFinished={filterFinished}
                                    setFilterFinished={setFilterFinished}
                                />
                            </div>
                            <LibrarySubjectFilter
                                filterSubject={resourceFilterSubject}
                                setFilterSubject={setResourceFilterSubject}
                                activeTab={activeTab}
                            />
                            <TabResources
                                resources={resources}
                                setResources={setResources}
                                isFetchingMoreResources={isFetchingMoreResources}
                                hasMoreResources={hasMoreResources}
                            />
                        </>
                    }
                </div>
            </div>
        );
    };

    export default LibraryTabs;