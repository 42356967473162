import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '../../Context/NavigationContext';
import BackButton from '../../Components/BackButton';
import Loader from '../../Components/Loader';
import ResourceCard from '../../Components/ResourceCard/ResourceCard';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import ResourcePageRightTabs from './Tabs';
import LearnedUsers from './LearnedUsers';

import { fetchSingleResource, useWindowSize } from '@linko/shared_utils';

// Custom hook to get window size

function ResourcePage() {
  const { id } = useParams();
  const resourceId = id;
  const location = useLocation();
  const navigate = useNavigate();
  const { pushPage } = useNavigation();
  const openAddNote = location.state?.openAddNote;
  const [resource, setResource] = useState(null);
  const [notes, setNotes] = useState([]);
  const [library, setLibrary] = useState([]);
  const [resourceType, setResourceType] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [learnedUserData, setLearnedUserData] = useState(null);
  const [shareView, setShareView] = useState(null);

  const { width } = useWindowSize(); // Use the custom hook

  // Determine resource type
  useEffect(() => {
    if (resource) { 
      if (resource.type === 'bo') {
        setResourceType('book');
      } else if (resource.type === 'vi') {
        setResourceType('video');
      } else if (resource.type === 'ar') {
        setResourceType('article');
      } else if (resource.type === 'po') {
        setResourceType('podcast');
      } else if (resource.type === 'oc') {
        setResourceType('online course');
      } else {
        setResourceType('resource');
      }
    }
  }, [resource]);
  
  // Fetch this resource info
  const fetchResource = async () => {
    const response = await fetchSingleResource(resourceId);
    setResource(response.resource);
    setLearnedUserData(response.learnedUsers);
    setShareView(response.user === null ? true : false);
  }

  useEffect(() => {
    setResource(null);
    fetchResource();
  }, [resourceId]);

  const onDelete = (id) => {
    navigate('/my_linko');
  }

  useEffect(() => {
    pushPage(location.pathname);
  }, [location.pathname]);

  if (!resource) return (
    <div className='page-loading'>
      <Loader />
    </div>
  );
  
  return (
    <div className='resource-page-container'>
      <div className='resource-page-right'>
        <BackButton />
        <div className='resource-page-resource' >
          <ResourceCard
            resource={resource}
            onDelete={onDelete}
            currentResource={true}
            shareView={false}
          />
          {width < 1024 && <ResourcePageRightTabs resource={resource} />}
        </div>
        <LearnedUsers learnedUserData={learnedUserData}/> 
        <AddNoteButton 
          subjectId={null}
          noteHint={`Take a note on this ${resourceType}.`}
          resource={resource}
          notes={notes} 
          setNotes={setNotes} 
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
        />
        <TheTabs
          notes={notes}
          setNotes={setNotes} 
          library={library}
          setLibrary={setLibrary} 
          resourceId={resourceId} 
          noteHint={`Take a note on this ${resourceType}.`}
          resourceType={resourceType}
          openAddNote={openAddNote}
          subjectId={null}
        />
      </div>
      {width >= 1024 && <ResourcePageRightTabs resource={resource}/>}
    </div> 
  );
}

export default ResourcePage;
