import React, { useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiNotePencilBold } from "react-icons/pi";
import ReactMarkdown from 'react-markdown';
import Loader from '../Loader';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';

const SearchResults = ({
    isLoading,
    users,
    resources,
    notes,
    subject,
    isFocused,
    searchTerm,
    setCloseAllowed,
}) => {

    const searchResultsRef = useRef(null);
    const sectionTitleRefs = useRef([]);
    sectionTitleRefs.current = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const { safeNavigate } = useContext(NavigationPromptContext);
    const navigate = useNavigate();

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        const url = `/profile/${initial}${profileCode}`;
        navigate(url);
    };

    const goToNote = (id) => {
        safeNavigate(`/n/${btoa(id)}`);
    };

    const truncateMarkdown = (markdown, maxLength) => {
        if (markdown.length <= maxLength) return markdown;
        let truncated = markdown.substr(0, maxLength);
        // Ensure we don't cut off in the middle of a markdown syntax
        truncated = truncated.replace(/\[([^\]]+)?$/, '');
        truncated = truncated.replace(/\*([^\*]+)?$/, '');
        truncated = truncated.replace(/\_([^\_]+)?$/, '');
        truncated = truncated.replace(/\`([^\`]+)?$/, '');
        return truncated + '...';
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        entry.target.classList.add('sticky');
                    } else {
                        entry.target.classList.remove('sticky');
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionTitleRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sectionTitleRefs.current.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    if (!isFocused || searchTerm === '') {
        return null;
    }

    return (
        <div className="full-search-results" ref={searchResultsRef}>
            {isLoading && <Loader />}
            {/* User */}
            {users?.length > 0 && ( 
                <div ref={sectionTitleRefs.current[0]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>Users</h2>
                    <div className='rx-divider-vertical'></div>
                    <div className='full-search-results-subjects'>
                        {users.length > 0 ? (
                            users.map(user => (
                                <div
                                    key={user.id}
                                    className="search-results-user"
                                    onClick={() => {
                                        setCloseAllowed(false);
                                        goToProfile(user.id, user.first_name, user.last_name);
                                    }}
                                >
                                    {user.first_name} {user.last_name}
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {/* Resource */}
            {resources?.length > 0 && (
                <div ref={sectionTitleRefs.current[1]} style={{ width: '100%' }}>
                    <h2 ref={sectionTitleRefs.current[2]} className='full-search-results-title'>My resources</h2>
                    <div className='rx-divider-vertical'></div>
                    <div className='full-search-results-subjects'>
                        {resources.length > 0 ? (
                            resources.map(resource => (
                                <>
                                <div
                                    key={resource.id}
                                    className="search-results-resource"
                                    onClick={() => {
                                        setCloseAllowed(false);
                                        safeNavigate(`/r/${resource.id}`);
                                    }}
                                >
                                    <ResourceTypeIcon type={resource.type} />
                                    {resource.title}
                                </div>
                                </>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
            {/* Note */}
            {notes?.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My notes</h2>
                    <div className='rx-divider-vertical'></div>
                    <div className='full-search-results-subjects'>
                        {notes.length > 0 ? (
                            notes.map(note => {
                                const truncatedNote = truncateMarkdown(note.note, 150);
                                return (
                                    <div
                                        key={note.id}
                                        className="search-results-resource"
                                        onClick={() => {
                                            setCloseAllowed(false);
                                            goToNote(note.id);
                                        }}
                                    >
                                        <PiNotePencilBold size={18} color='#999999' style={{flexShrink:'0'}}/>
                                        <div className='search-results-note-content'>
                                            {note.title && <div className="note-title">{note.title}</div>}
                                            <ReactMarkdown className="note-preview">
                                                {truncatedNote}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                );
                            })
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
            {/* Subject */}
            {subject?.studied.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My subjects</h2>
                    <div className='rx-divider-vertical'></div>
                    <div className='full-search-results-subjects'>
                        {subject.studied.length > 0 ? (
                            subject.studied.map(subject => (
                                <div
                                    key={subject.id}
                                    className={`subject-tag ${subject.is_linked ? 'studied' : 'cus-label'}`}
                                    onClick={() => { safeNavigate(`/s/${encodeURIComponent(subject.name)}`); setCloseAllowed(false); }}
                                >
                                    {subject.name}
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
            {subject?.notStudied.length > 0 && (
                <div ref={sectionTitleRefs.current[3]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>All subjects</h2>
                    <div className='rx-divider-vertical'></div>
                    <div className='full-search-results-subjects'>
                        {subject.notStudied.length > 0 ? (
                            subject.notStudied.map(subject => (
                                <div
                                    key={subject.id}
                                    className="subject-tag not-studied"
                                    onClick={() => { safeNavigate(`/s/${encodeURIComponent(subject.name)}`); setCloseAllowed(false); }}
                                >
                                    {subject.name}
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchResults;
